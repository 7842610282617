import './tailwind_theme/tailwind.css';
import Header from "../component/header";
import Footer from "../component/footer";

const TermsOfUsePage = () => {
    return (
        <>
            <Header/>

            {/* Terms of Use Section */}
            <section className="bg-white py-12">
                <div className="container mx-auto">
                    <h2 className="text-4xl font-bold text-gray-900 mb-8">Terms of Use</h2>
                    <div className="text-lg text-gray-700 dark:text-black">
                        <p className="mb-6">
                            The following terms and conditions constitute an agreement between you and GenSE Sdn Bhd
                            (Company No. 951151-A) (“GenSE,” “we,” or “us”), the operator of the DTC Car Dealership
                            website and related services and mobile applications (“Application”) provided by us and in
                            respect of which these Terms of Use are referenced (collectively, the “Services”). These
                            terms of use (the “Terms of Use”), together with our Privacy Policy located at DTC Car
                            Dealership (which is incorporated herein by reference, and collectively, this “Agreement”)
                            govern your use of the Services.
                        </p>
                        <p className="mb-6">
                            By using or otherwise accessing the Services, you agree to the terms of this Agreement. If
                            you do not agree with this Agreement, you must refrain from accessing or using the Services.
                            Please note that we offer the Services "AS IS" and without warranties. You are required to
                            register and authorize the use and disclosure of your information for purposes of allowing
                            us to provide the Services and as otherwise disclosed in our Privacy Policy.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">1. SERVICES</h3>
                        <p className="mb-6">
                            The services and features of the Services enable you to make online appointments with
                            participating car dealerships, service providers, or other automotive specialists,
                            professionals, or providers (“Automotive Providers”) through the Site, the Application
                            and/or the Services. Once an online appointment request is submitted by you through the
                            Services, such request is considered accepted by the Automotive Providers, until and unless
                            you cancel the appointment or if the Automotive Providers cancel the appointment. When the
                            requested appointment is cancelled by the Automotive Providers (“Cancelled Appointment”), we
                            will provide notification of such Cancelled Appointment to you through the Application
                            and/or by email.
                        </p>
                        <p className="mb-6">
                            We have no control over, and cannot guarantee the availability of any Automotive Providers
                            at any particular time. We will not be liable for cancelled or otherwise unfulfilled
                            appointments, or any injury resulting therefrom, or for any other injury resulting or
                            arising from or related to the use of the Services.
                        </p>
                        <p className="mb-6">
                            We use reasonable efforts to ensure that Automotive Providers only participate in the
                            Services if they hold all current licenses required by law to operate in the country. We may
                            exclude Automotive Providers who, in our discretion, have engaged in inappropriate or
                            unprofessional conduct. Notwithstanding the foregoing, the Automotive Providers shall be
                            solely responsible for all losses or damages incurred or suffered by you arising out of or
                            in connection with your visit to the Automotive Providers or from any goods or services of
                            the Automotive Providers. We are neither an agent of nor are we connected with any
                            Automotive Providers.
                        </p>
                        <p className="mb-6">
                            No professional/client relationship is created by using the Site, the Application, the
                            Services and/or the Content (herein defined). The procedures, products, services and devices
                            discussed and/or marketed through the Services are not applicable to all individuals or all
                            situations.
                        </p>
                        <p className="mb-6">
                            We reserve the right to suspend and/or terminate your account at any time which will result
                            in your inability to use the Services.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">2. CONTENT</h3>
                        <p className="mb-6">
                            You acknowledge that although some Content may be provided by individuals or other users of
                            the Services who are in the automotive profession, the provision of such Content does not
                            create a professional/client relationship, does not constitute an opinion, advice, diagnosis
                            or treatment nor is it a substitute for the same. The Content is provided solely to assist
                            you with locating an Automotive Provider for your needs. “Content” means content, text,
                            data, graphics, images, photographs, video, audio, information, suggestions, guidance, and
                            other materials provided, made available or otherwise found through the Services, the
                            Application and/or Site, including without limitation Content provided in direct response to
                            your questions or postings. Your use of the Content is solely at your own risk.
                        </p>
                        <p className="mb-6">
                            The Content that you obtain or receive from DTC Car Dealership, and its employees,
                            contractors, partners, sponsors, advertisers, licensors or otherwise through the Services
                            are purely for informational, educational, scheduling and pricing purposes only.
                        </p>
                        <p className="mb-6">
                            We may, but have no obligation to, have Content posted through the Services reviewed by our
                            editorial personnel. It is important to note, however, that the timeliness, accuracy, and
                            completeness of any or all of the Content are not guaranteed. We will not be responsible for
                            any errors or omissions or for the results obtained from the use of such Content.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">3. NO WARRANTIES IN RELATION TO AUTOMOTIVE
                            PROVIDERS</h3>
                        <p className="mb-6">
                            Automotive Providers listed through the Services with whom you may book appointments enter
                            into contracts with us and may pay us a fee for the services we provide to them through the
                            Services. We will provide you with lists and/or profiles of Automotive Providers who you may
                            find suitable to provide the services you need. Notwithstanding the foregoing, we do not:
                        </p>
                        <ul className="list-disc ml-6 mb-6">
                            <li>(a) recommend or endorse any Automotive Providers and</li>
                            <li>(b) make any representations or warranties with respect to these Automotive Providers or
                                the quality of the services they may provide.
                            </li>
                        </ul>

                        <h3 className="text-2xl font-semibold mb-4">4. REGISTRATION</h3>
                        <p className="mb-6">
                            As part of the registration process, you are required to provide a user name and create a
                            password. These are your registration details for accessing the Services that are only
                            available to users ("Registration Details"). The Registration Details must be kept
                            confidential at all times and must not be disclosed to anyone else. You must immediately
                            notify us if your password has been stolen or compromised by sending an email to
                            hello@dtc-cars.com.
                        </p>
                        <p className="mb-6">
                            You must be 13 years of age or over, or the legal age to form a binding contract in your
                            jurisdiction if that age is greater than 13 years of age, to register with us or use the
                            Site, the Application and the Services. If you are below the age of 13 or the applicable
                            legal age in your jurisdiction, you can use the Site, the Application or Services only under
                            the supervision of your parent or guardian who has agreed to these Terms of Use. If you are
                            the parent or legal guardian of a child under the age of 13, you may use the Site, the
                            Application or Services on behalf of such minor. By using the Site, the Application or
                            Services on behalf of such minor, you represent and warrant that you are the parent or legal
                            guardian of such minor and that all references in these Terms of Use to “you” shall refer to
                            such minor or such other individual for whom you have authorization to enter into these
                            Terms of Use on their behalf and you in your capacity as the parent or legal guardian of
                            such minor or as the authorized party of such individual. If you do not qualify under these
                            terms, do not use the Site, the Application or Services. Registration of the Services is
                            void where prohibited by applicable law and the right to access the Site is revoked in such
                            jurisdictions. By using the Site, the Application and/or the Services, you represent and
                            warrant that you have the right, authority, and capacity to enter into these Terms of Use
                            and to abide by all of the terms and conditions set forth herein. The Site is administered
                            in Malaysia and intended for Malaysian users; any use outside of Malaysia is at the user's
                            own risk and users are responsible for compliance with any local laws applicable to their
                            use of the Services, the Application or the Site.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">5. USER OBLIGATIONS</h3>
                        <p className="mb-6">
                            By registering for access to the Site or the Application and use of the Services, you agree
                            to abide by the following terms and conditions: (1) you are responsible for protecting the
                            confidentiality of your username and password and the safety and security of any computer,
                            mobile phone and/or any other equipment and/or hardware you use to access the Services; (2)
                            you will not post or transmit any material or information which is offensive, defamatory,
                            obscene, unlawful, vulgar, harmful, threatening, abusive, harassing or ethnically
                            objectionable; (3) you agree not to impersonate any other person; (4) you agree to provide
                            current, accurate and up-to-date information about yourself as required under these terms
                            and conditions; (5) you agree not to post or transmit any unsolicited advertising or
                            promotional materials; (6) you will not post or transmit any material in which the copyright
                            is owned by another person or entity and you warrant that all material posted or transmitted
                            is your original work and not sourced from any third party; (7) you will not post or
                            transmit any material which contains viruses or other computer codes, files or programs
                            which are designed to limit or destroy the functionality of other computer software or
                            hardware; and (8) you accept that any information provided by us is general information and
                            is not in the nature of advice. We derive our information from sources which we believe to
                            be accurate and up to date as at the date of publication and reserve the right to update
                            this information at any time.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">6. YOUR PERSONAL INFORMATION</h3>
                        <p className="mb-6">
                            We collect and process your personal data in accordance with the Privacy Policy at DTC Car
                            Dealership and you hereby consent to the processing of your personal data in accordance with
                            the said notice.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">7. YOUR RESPONSIBILITIES</h3>
                        <p className="mb-6">
                            Even though the Services are provided free of charge, charges for any automotive or related
                            services rendered by Automotive Providers will apply and will be entirely your
                            responsibility. You must resolve any dispute between you and any Automotive Providers
                            arising from any transaction hereunder directly with the Automotive Providers.
                        </p>
                        <p className="mb-6">
                            Any information which you transmit to us is transmitted at your own risk. Nevertheless, once
                            we receive your transmission, we will take reasonable steps to preserve the security of such
                            information. We reserve the right to remove your username or similar identifier in respect
                            of your account if appropriate. You acknowledge sole responsibility for and assume all risk
                            arising from your access and use of the Services.
                        </p>
                        <p className="mb-6">
                            You may only use the Site, the Application and the Services for lawful, non-commercial
                            purposes. You may not use the Site in any manner that could damage, disable, overburden,
                            impair our servers or networks, or interfere with any other party’s use and enjoyment of the
                            Site or the Services. You may not attempt to gain unauthorized access to any of the
                            Services, user accounts, computer systems or networks through hacking, password mining or
                            any other means. You may not accumulate or index, directly or indirectly, any Content or
                            portion of the Site, the Application and/or Services for any purpose whatsoever.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">8. CHANGES TO THE SERVICES</h3>
                        <p className="mb-6">
                            We may from time to time add new services to the Services, substitute a new service for one
                            of the existing Services, discontinue or suspend one of the existing Services. The use of
                            new services will be governed by this Agreement. You agree that DTC Car Dealership will not
                            be liable to you or any third party for any suspension or discontinuation of any of the
                            Services or portion thereof.
                        </p>
                        <p className="mb-6">
                            It may be necessary for us to perform scheduled or unscheduled repairs or maintenance, or
                            remotely patch or upgrade the Services, which may temporarily degrade the quality of the
                            Services or result in a partial or complete outage of the Services. In this regard, we will
                            not be liable to you or any third party for any such degradation in the quality or outage of
                            the Services.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">9. CONTENT YOU POST OR SUBMIT</h3>
                        <p className="mb-6">
                            You may have in the future the opportunity to submit feedback regarding your experiences
                            with Automotive Providers featured through the Services, to submit inquiries concerning
                            possible automotive needs, to participate in the other interactive and community features of
                            the Site (collectively “Posted Information”). It is important that you act responsibly when
                            providing Posted Information and you shall at all times comply with the above section on
                            User Obligations.
                        </p>
                        <p className="mb-6">
                            We reserve the right to investigate and take appropriate legal action against anyone who
                            breaches this provision or the above section on User Obligations, including without
                            limitation, removing any offending communication from the Services and terminating the
                            account of such violators or blocking your use of the Services.
                        </p>
                        <p className="mb-6">
                            By posting Posted Information through the Services, you agree to and hereby do grant, and
                            you represent and warrant that you have the right to grant to us and our contractors an
                            irrevocable, perpetual, royalty-free, fully sublicensable, fully paid up, worldwide license
                            to use, copy, publicly perform, digitally perform, publicly display, distribute such Posted
                            Information to adapt, edit, translate, prepare derivative works of, or incorporate into
                            other works, such Posted Information. You agree that we are entitled to combine your Posted
                            Information with the Posted Information of other DTC Car Dealership users for purposes of
                            constructing or populating a searchable database of reviews and information related to the
                            automotive industry.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">10. INTELLECTUAL PROPERTY RIGHTS</h3>
                        <p className="mb-6">
                            Copyright in the Services and the Content (including text, graphics, logos, icons, sound
                            recordings, video recordings and software) is owned or licensed by us. You acknowledge that
                            the Services and any underlying technology or software used in connection with the Services
                            contain our proprietary information. We give you permission to use the Services and the
                            Content for personal, non-commercial purposes only and do not transfer any intellectual
                            property rights to you by virtue of permitting your use of the Services. Except as expressly
                            authorised by these terms and conditions, you may not in any form or by any means: (1)
                            adapt, reproduce, store, distribute, print, display, perform, publish or create derivative
                            works from any part of the Services; (2) commercialise any information, products or services
                            obtained from any part of the Services; (3) modify, adapt, sublicense, translate, sell,
                            reverse engineer, decipher, decompile or otherwise disassemble any portion of the Site, the
                            Application and/or the Services or use any part of the same to provide or incorporate into,
                            any product or service provided to a third party; or (4) download or store the Content.
                            Subject to the foregoing restrictions, we grant you a non-exclusive, non-transferable,
                            revocable license to use the Site and the Application, in object code form only, on your
                            compatible mobile, tablet or other handheld computing devices, solely for your permitted use
                            of the Services.
                        </p>
                        <p className="mb-6">
                            If you use any of our trademarks, rights in internet domain names and website addresses and
                            other rights in trade names (“Marks”) in reference to our activities, products or services,
                            you must include a statement attributing the Marks to us. You must not use any of the Marks:
                            (1) in or as the whole or part of your own trademarks; (2) in connection withactivities,
                            products or services which are not ours; (3) in a manner which may be confusing, misleading
                            or deceptive; and/or (4) in a manner which disparages us or our information, products or
                            services (including the Services). <h3 className="text-2xl font-semibold mb-4">11. LINKS TO
                            OTHER SITES</h3>
                            <p className="mb-6">
                                The Services may contain links to other websites (“Linked Websites”). Such links are
                                provided for convenience only and may not remain current or be maintained. We are not
                                responsible for the content or privacy practices associated with the Linked Websites.
                                The Linked Websites should not be construed as an endorsement, approval or
                                recommendation by us of the owners or operators of those Linked Websites, or of any
                                information, graphics, materials, products or services referred to or contained on those
                                Linked Websites, unless and to the extent stipulated to the contrary.
                            </p>

                            <h3 className="text-2xl font-semibold mb-4">12. PROHIBITED ACTIVITIES</h3>
                            <p className="mb-6">
                                We may (but shall be under no obligation to) investigate if you have misused the Site,
                                the Services and/or the Application, or behave in a way, which we regard as
                                inappropriate, unlawful or illegal. The following is a non-exhaustive list of prohibited
                                activities which you shall not engage in with respect to the Site, the Services and/or
                                the Application. We reserve the right to amend this list at any time. The prohibited
                                activities include, without limitation: (1) sending or posting or distributing
                                materials, which constitutes or encourages conduct that would be considered a criminal
                                offence in Malaysia or any other country in the world; (2) impersonating any person or
                                entity, falsely state or otherwise misrepresent your affiliation with any person or
                                entity in connection with the Site; (3) expressing or implying that any statements you
                                make are endorsed by us without our specific prior written consent; (4) using any robot,
                                spider, or other device or process to retrieve, index, or in any way reproduce or
                                circumvent the navigational structure or presentation of the Services; (5) "frame" or
                                "mirror" any part of the Site, the Services and/or the Application, without our prior
                                written authorization; (6) using any code or other devices containing any reference to
                                us, the Site, the Services and/or the Application to direct persons to any other website
                                and/or service; (7) using the Services or content contained in the Site and/or the
                                Application for any illegal, fraudulent or harmful purpose; (8) interfering with or
                                disrupting the operation of the Site or the servers or network used to make the Site
                                available; or violate any requirements, procedures, policies or regulations of such
                                networks; (9) violating any laws; (10) publicly disseminate information regarding the
                                performance of the Site, the Services and/or the Application or access or use the Site,
                                the Services and/or the Application for competitive analysis or benchmarking purposes.
                            </p>

                            <h3 className="text-2xl font-semibold mb-4">13. DISCLAIMER</h3>
                            <p className="mb-6">
                                You release us from all liability for you having acquired or not acquired Content
                                through the Services. We make no representations concerning any Content contained in or
                                accessed through the Services, and we will not be responsible or liable for the
                                accuracy, copyright compliance, legality or decency of material contained in or accessed
                                through the Services. We make no representations or warranties regarding suggestions or
                                recommendations or endorsements of services or products offered or purchased through the
                                Services.
                            </p>
                            <p className="mb-6">
                                To the maximum extent permitted by law, we hereby disclaim all statutory warranties,
                                with respect to the Services, the Application and the Site, including without limitation
                                any warranties that the Services are merchantable, of satisfactory quality, accurate,
                                fit for a particular purpose or need, or non-infringing. We do not guarantee that you
                                will be able to access or use the Services (either directly or through third-party
                                networks) at times or locations of your choosing. We are not responsible for the
                                accuracy, reliability, timeliness or completeness of information provided by users of
                                the Services or any other data or information provided or received through the Services.
                                Except as expressly set forth herein we make no warranties about the Services or any
                                other security associated with the transmission of sensitive information. We do not
                                warrant that the Site, the Application or the Services will operate error-free, bug-free
                                or free from defects, that loss of data will not occur, or that the Services,
                                Application or Site are free of computer viruses, contaminants or other harmful items.
                            </p>

                            <h3 className="text-2xl font-semibold mb-4">14. LIMITATION OF LIABILITY</h3>
                            <p className="mb-6">
                                Your sole and exclusive remedy for any dispute with us is the cancellation of your
                                registration. In no event shall our total cumulative liability to you for any and all
                                claims relating to or arising out of your use of the Services, the Application or the
                                Site, regardless of the form of action, exceed RM10.
                            </p>
                            <p className="mb-6">
                                In no event shall we be liable to you (or to any third party claiming under or through
                                you) for any indirect, special, incidental, consequential or exemplary damages arising
                                from your use of, or inability to use, the Site, the Application and/or the Services.
                                These exclusions apply to any claims for lost profits, lost data, loss of goodwill,
                                computer failure or malfunction, any other commercial damages or losses, or negligence
                                of Automotive Providers utilized through the use of the Services, even if we knew or
                                should have known of the possibility of such damages.
                            </p>

                            <h3 className="text-2xl font-semibold mb-4">15. INDEMNIFICATION</h3>
                            <p className="mb-6">
                                You agree to indemnify us against any loss, damage or cost incurred by us arising out of
                                your access or use of the Site, including, without limitation, the Services, the
                                Application, the Content or any other information accessible over or through the Site
                                and/or the Application, any content submitted by you or your violation of these terms
                                and conditions, or any other laws, regulations and rules. You will also indemnify us
                                against any claims that information or material which you have submitted to us is in
                                violation of any law or in breach of any third party rights (including, but not limited
                                to, claims in respect of defamation, breach of confidence, infringement of copyright or
                                infringement of any other intellectual property right).
                            </p>

                            <h3 className="text-2xl font-semibold mb-4">16. TERMINATION</h3>
                            <p className="mb-6">
                                We may terminate and/or suspend your registration immediately, without notice, if there
                                has been a breach of this Agreement or other policies and terms posted on the Site, the
                                Application or through the Services by you or by someone using your Registration
                                Details. We may also cancel or suspend your registration for any other reason, including
                                inactivity for an extended period. We shall not be liable to you or any third party for
                                any termination of your access to the Site, Application and/or the Services. Further,
                                you agree not to attempt to use the Site, Application and/or the Services after any such
                                deletion, deactivation or termination (provided, in the case of deactivation due
                                exclusively to your inactivity, you may be permitted to re-register). Sections 2, 3, 6,
                                9, 10, 13, 14, 15, 16, and 17 shall survive any termination or expiration of these Terms
                                of Use.
                            </p>

                            <h3 className="text-2xl font-semibold mb-4">17. GENERAL</h3>
                            <p className="mb-6">
                                <strong>17.1 Amendments to These Terms of Use:</strong> We reserve the right to amend
                                these Terms of Use and the other documents consisting of the Agreement at any time. Upon
                                any such change, we will post the amended terms on the Site or we may also attempt to
                                notify you via electronic or conventional mail. Your continued access to and/or use of
                                the Site, the Application and/or the Services following such posting and/or notice shall
                                constitute your agreement to be bound by these Terms of Use or other applicable
                                Agreement documents, as amended. If at any time you choose not to accept these Terms of
                                Use, including following any such modifications hereto, then you must stop using the
                                Site, the Application and the Services.
                            </p>
                            <p className="mb-6">
                                <strong>17.2 Severability:</strong> If any of these Terms of Use are held to be invalid,
                                unenforceable or illegal for any reason, the remaining terms and conditions shall
                                nevertheless continue in full force.
                            </p>
                            <p className="mb-6">
                                <strong>17.3 Governing Law:</strong> This Agreement shall be governed by the laws of
                                Malaysia and the courts of Malaysia shall have non-exclusive jurisdiction.
                            </p>
                            <p className="mb-6">
                                <strong>17.4 Non-waiver:</strong> Our failure to exercise or enforce any right or
                                provision of this Agreement shall not constitute a waiver of such right or provision.
                                The failure of either party to exercise in any respect any right provided for herein
                                shall not be deemed a waiver of any further rights hereunder.
                            </p>
                            <p className="mb-6">
                                <strong>17.5 Entire Agreement:</strong> This Agreement and any supplemental terms,
                                policies, rules and guidelines posted through the Services, including the Privacy
                                Policy, constitute the entire agreement between you and us and supersede all previous
                                written or oral agreements.
                            </p>                    <p className="mb-6">
                            <strong>17.6 Headings:</strong> The headings in this Agreement are for convenience only, do
                            not form a part hereof, and in no way limit, define, describe, modify, interpret or construe
                            the meaning, scope or intent of this Agreement or any terms or conditions therein.
                        </p>
                            <p className="mb-6">
                                <strong>17.7 Non-assignment:</strong> You may not assign, transfer or sublicense this
                                Agreement to anyone else and any attempt to do so in violation of this section shall be
                                null and void.
                            </p>
                        </p>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    );
};

export default TermsOfUsePage;
