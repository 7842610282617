import './tailwind_theme/tailwind.css';
import Header from "../component/header";
import Newsletter from "../component/newsletter";
import DownloadSection from "../component/download";
import Footer from "../component/footer";

const LocationPage = () => {
    return (<>
            <Header/>
            <div className="container my-12 py-12 mx-auto px-4 md:px-6 lg:px-12">
                {/*Section: Design Block*/}
                <section className="mb-20 text-gray-800">
      <div className="flex flex-wrap justify-center">
        <div className="flex-initial shrink w-full xl:w-5/12 lg:w-6/12">
          <div className="lg:py-12 lg:pl-6 mb-6 lg:mb-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15935.041635604075!2d101.7371823!3d3.1577482!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc37b61e65b8d7%3A0x67fba32b5c10eb34!2sDTC%20MOTORS%20SDN.BHD%20(HQ)!5e0!3m2!1sen!2smy!4v1708926111857!5m2!1sen!2smy"
              className="h-80 w-full border-0 rounded-lg shadow-lg"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
          <div className="lg:py-2 lg:pl-6 mb-6 lg:mb-2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!4v1713753432376!6m8!1m7!1s32gecoLZLjPMX9UGILsv_A!2m2!1d3.159208987137807!2d101.7298834730289!3f73.95589!4f0!5f0.7820865974627469"
              className="h-80 w-full border-0 rounded-lg shadow-lg"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </div>
        <div className="flex-initial shrink w-full xl:w-7/12 lg:w-6/12 mb-6 md:mb-0 lg:-ml-12">
          <div
            className="bg-red-500 border border-primary-500 h-full rounded-lg p-6 lg:pl-12 text-white flex items-center py-12 lg:py-0"
            style={{ zIndex: -10 }}
          >
            <div className="lg:pl-12">
              <h2 className="text-2xl font-semibold uppercase mb-6 pb-2">
                DTC Motors | Kuala Lumpur
              </h2>
              <h5 className="text-xl font-semibold mb-2">Address:</h5>
              <p className="mb-6">
                Lot 253, Jalan Ampang Hilir, Desa Pahlawan, 50450 Kuala Lumpur,
                Wilayah Persekutuan Kuala Lumpur
              </p>
              <h5 className="text-xl font-semibold mb-4">Phone Number:</h5>
              <p className="mb-6">+ 03-2144 3110</p>
              <h5 className="text-xl font-semibold mb-4">Email:</h5>
              <p className="mb-6">dtcmsb.2020@gmail.com</p>
              <div className="flex flex-row">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  className="w-6 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                  ></path>
                </svg>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  className="w-6 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  ></path>
                </svg>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-6">
                <div className="grid gap-4">
                  <div>
                    <img
                      className="h-32 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipPGa4tzFolaxo_taGbwySUv4c7z4fq_6mRTNzZQ=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="h-24 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipNJBs3NzJtwsODq0fleor7jlLaUw1pm1UFOIFFR=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                </div>
                <div className="grid gap-4">
                  <div>
                    <img
                      className="h-24 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipNLlKSJOuTDALy37lguS1eYUsZ-za_Kznt54TO3=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="h-32 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipO2-dmEOQVhnFUyo1vBoB52B6rHh1JLzaLYm_WY=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                </div>
                <div className="grid gap-4">
                  <div>
                    <img
                      className="h-32 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipOgdNRgA-F3Jy-u6MXaI5NRNhv3SaAzd8tCW8k0=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="h-24 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipP0Ldzvw0NueBVzmdla_eKoYc-yukdgXqB31QNF=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                </div>
                <div className="grid gap-4">
                  <div>
                    <img
                      className="h-20 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipM7_TBEb8fxTL2joCRO_jJzPu23KcLGQ36AV1Xj=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="h-32 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipPnZpOu6fy5esrh27uLUd6qSemAKC4Xf9xPrMfg=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="mb-20 text-gray-800">
      <div className="flex flex-wrap justify-center">
        <div className="flex-initial shrink w-full xl:w-5/12 lg:w-6/12">
          <div className="lg:py-12 lg:pl-6 mb-6 lg:mb-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.757945641919!2d101.73097847424226!3d3.158390296816973!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc37a649763975%3A0x77d1b800749addb8!2sDTC%20Carlifestyle%20(2)!5e0!3m2!1sen!2smy!4v1716517214696!5m2!1sen!2smy"
              className="h-80 w-full border-0 rounded-lg shadow-lg"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
          <div className="lg:py-2 lg:pl-6 mb-6 lg:mb-2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!4v1716517316842!6m8!1m7!1sh8QByt0OdkOaPnTHccMKIQ!2m2!1d3.15826561746626!2d101.7335229824978!3f13.9009!4f0!5f0.7820865974627469" 
              className="h-80 w-full border-0 rounded-lg shadow-lg"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </div>
        <div className="flex-initial shrink w-full xl:w-7/12 lg:w-6/12 mb-6 md:mb-0 lg:-ml-12">
          <div
            className="bg-red-500 border border-primary-500 h-full rounded-lg p-6 lg:pl-12 text-white flex items-center py-12 lg:py-0"
            style={{ zIndex: -10 }}
          >
            <div className="lg:pl-12">
              <h2 className="text-2xl font-semibold uppercase mb-6 pb-2">
                DTC Motors | Kuala Lumpur (2)
              </h2>
              <h5 className="text-xl font-semibold mb-2">Address:</h5>
              <p className="mb-6">12, Jalan Ampang Hilir, Taman U Thant, 55000 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur</p>
              <h5 className="text-xl font-semibold mb-4">Phone Number:</h5>
              <p className="mb-6">+03-4266 8100</p>
              <h5 className="text-xl font-semibold mb-4">Email:</h5>
              <p className="mb-6">dtcmsb.2020@gmail.com</p>
              <div className="flex flex-row">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  className="w-6 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                  ></path>
                </svg>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  className="w-6 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  ></path>
                </svg>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-6">
                <div className="grid gap-4">
                  <div>
                    <img
                      className="h-32 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipN6gCaFzW_T5toSdyJNzmpe8jjvCw4ShPbpIWDV=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="h-24 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipPJAMuzawuU3pQg4H4EJ9DXY_DtNK1_N3DgBGaY=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                </div>
                <div className="grid gap-4">
                  <div>
                    <img
                      className="h-24 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipMRThqOnR59wKn5EmY8gkKamOlnHwEtgP_Jel7X=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="h-32 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipNsIYUkrG5-eUWKfn38fmqhyD6GwKeBRh3BPgT1=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                </div>
                <div className="grid gap-4">
                  <div>
                    <img
                      className="h-32 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipNy7-wzWk1yWFOxBg6YolJgE9REkqrFDn-4EuKz=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="h-24 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipNzblTQixyKINdnTyKE7jEBXUKTLyf2D14wZkMf=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                </div>
                <div className="grid gap-4">
                  <div>
                    <img
                      className="h-20 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipPGYuKEbJmDR5hgoLgKEax83GaG-zYxYSPoBnWC=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="h-32 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipNhCgs3890CRyeFcPPjgkbCHZbLTvisROWfSWMR=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

                <section className="mb-20 text-gray-800">
      <div className="flex flex-wrap justify-center">
        <div className="flex-initial shrink w-full xl:w-5/12 lg:w-6/12">
          <div className="lg:py-12 lg:pl-6 mb-6 lg:mb-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3980.9469830552775!2d103.32996637427028!3d3.8215198486531374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31c8bb658dfc712b%3A0x58da9e7ed6f06ec4!2sDTC%20Motor%20Kuantan!5e0!3m2!1sen!2smy!4v1708926271114!5m2!1sen!2smy"
              className="h-80 w-full border-0 rounded-lg shadow-lg"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
          <div className="lg:py-2 lg:pl-6 mb-6 lg:mb-2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!4v1713755849420!6m8!1m7!1scmWOuYij6lkOlh9G2m3xTw!2m2!1d3.821498629349812!2d103.3323820411577!3f85.565765!4f0!5f0.7820865974627469"
              className="h-80 w-full border-0 rounded-lg shadow-lg"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </div>
        <div className="flex-initial shrink w-full xl:w-7/12 lg:w-6/12 mb-6 md:mb-0 lg:-ml-12">
          <div
            className="bg-red-500 border border-primary-500 h-full rounded-lg p-6 lg:pl-12 text-white flex items-center py-12 lg:py-0"
            style={{ zIndex: -10 }}
          >
            <div className="lg:pl-12">
              <h2 className="text-2xl font-semibold uppercase mb-6 pb-2">
                DTC Motors | Kuantan
              </h2>
              <h5 className="text-xl font-semibold mb-2">Address:</h5>
              <p className="mb-6">2, 25300 Kuantan, Pahang</p>
              <h5 className="text-xl font-semibold mb-4">Phone Number:</h5>
              <p className="mb-6">+095159957</p>
              <h5 className="text-xl font-semibold mb-4">Email:</h5>
              <p className="mb-6">dtcmsb.2020@gmail.com</p>
              <div className="flex flex-row">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  className="w-6 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                  ></path>
                </svg>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  className="w-6 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  ></path>
                </svg>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-6">
                <div className="grid gap-4">
                  <div>
                    <img
                      className="h-32 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipPGa4tzFolaxo_taGbwySUv4c7z4fq_6mRTNzZQ=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="h-24 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipNJBs3NzJtwsODq0fleor7jlLaUw1pm1UFOIFFR=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                </div>
                <div className="grid gap-4">
                  <div>
                    <img
                      className="h-24 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipP4k2kuwr4G6QSJBmrgItSyuWNH20wriGdjU9_h=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="h-32 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipO2-dmEOQVhnFUyo1vBoB52B6rHh1JLzaLYm_WY=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                </div>
                <div className="grid gap-4">
                  <div>
                    <img
                      className="h-32 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipOgdNRgA-F3Jy-u6MXaI5NRNhv3SaAzd8tCW8k0=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="h-24 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipP0Ldzvw0NueBVzmdla_eKoYc-yukdgXqB31QNF=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                </div>
                <div className="grid gap-4">
                  <div>
                    <img
                      className="h-20 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipM7_TBEb8fxTL2joCRO_jJzPu23KcLGQ36AV1Xj=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="h-32 w-28 rounded-lg"
                      src="https://lh3.googleusercontent.com/p/AF1QipPnZpOu6fy5esrh27uLUd6qSemAKC4Xf9xPrMfg=s1360-w1360-h1020"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
            </div>
            <Footer/>
        </>
    );
};

export default LocationPage;
