import React, {useEffect, useState} from 'react';
import {API} from "../config/config";

import './tailwind_theme/tailwind.css';
import axios from "axios";
import {Link, useHistory} from "react-router-dom";

import Header from "../component/header";
import Newsletter from "../component/newsletter";
import DownloadSection from "../component/download";
import Footer from "../component/footer";
import BackgroundCarousel from '../component/carousel';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const images = [
    "https://lh3.googleusercontent.com/p/AF1QipNJBs3NzJtwsODq0fleor7jlLaUw1pm1UFOIFFR=s1360-w1360-h1020",
    "https://lh3.googleusercontent.com/p/AF1QipP5JqJ1zmEBdGPti2HhXLuSg-dwMgq28vAhrBOc=s1360-w1360-h1020",
    "https://lh3.googleusercontent.com/p/AF1QipP5TPLu7zWyJu6vgyI2O7JV9QkrOejAJcui6YGJ=s1360-w1360-h1020"
];

const NextArrow = ({ onClick }) => {
    return (
        <div className="slick-arrow slick-next" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right w-8 h-8 text-white"
            >
                <line x1="5" y1="12" x2="19" y2="12" />
                <polyline points="12 5 19 12 12 19" />
            </svg>
        </div>
    );
};

const PrevArrow = ({ onClick }) => {
    return (
        <div className="slick-arrow slick-prev" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-left w-8 h-8 text-white"
            >
                <line x1="19" y1="12" x2="5" y2="12" />
                <polyline points="12 19 5 12 12 5" />
            </svg>
        </div>
    );
};

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
};


const WebsiteIndex = () => {
    const [tooltipVisible, setTooltipVisible] = useState({});

    const showTooltip = (id) => setTooltipVisible({...tooltipVisible, [id]: true});
    const hideTooltip = (id) => setTooltipVisible({...tooltipVisible, [id]: false});

    const [stocks, setStocks] = useState([]);
    const [soldStocks, setSoldStocks] = useState([]);

    const [filters, setFilters] = useState({
        carName: '',
        model: '',
        bodyType: '',
        priceRange: '',
        yearRange: '',
        transmission: '',
        mileage: '',
        color: '',
        center: ''
    });
    const history = useHistory();

    useEffect(() => {
        fetchStocks();
        fetchSoldStocks();
    }, []);

    const fetchStocks = async () => {
        try {
            const response = await axios.get(`${API}/directory/stock/unsold/all`);
            console.log(response.data.data);
            setStocks(response.data.data);
        } catch (error) {
            console.error('Error fetching stocks:', error);
        }
    };

    const fetchSoldStocks = async () => {
        try {
            const response = await axios.get(`${API}/directory/stock/sold/all`);
            console.log(response.data.data);
            setSoldStocks(response.data.data);
        } catch (error) {
            console.error('Error fetching sold stocks:', error);
        }
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFilters(prevFilters => ({...prevFilters, [name]: value}));
    };

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();  // Prevent form submission on reset only if event is provided
        // Convert filters to query parameters and navigate to the ListingPage
        const query = new URLSearchParams(filters).toString();
        history.push(`/list?${query}`);
    };

    const handleReset = (e) => {
        if (e) e.preventDefault();  // Prevent form submission on reset only if event is provided
        setFilters({
            model: '',
            bodyType: '',
            priceRange: '',
            yearRange: '',
            transmission: '',
            mileage: '',
            color: '',
            center: ''
        });
    };

    const handleCategoryClick = (bodyType) => {
        // Reset all filters except for bodyType
        const resetFilters = {
            carName: '',
            model: '',
            bodyType,
            priceRange: '',
            yearRange: '',
            transmission: '',
            mileage: '',
            color: '',
            center: ''
        };


        const query = new URLSearchParams(resetFilters).toString();
        history.push(`/list?${query}`);
    };

    return (
        <>
            <Header/>
            <main>
            <section className="relative">
            <Slider {...settings} className="w-full h-screen">
            {images.map((image, index) => (
                <div key={index} className="w-full h-screen relative">
                    <div className="absolute inset-0 bg-black opacity-50"></div> {/* Semi-transparent overlay */}
                    <img src={image} alt={`Slide ${index + 1}`} className="w-full h-full object-cover" />
                </div>
            ))}
            </Slider>
  <div className="absolute inset-0 flex mt-60 flex-col items-center justify-center">
                <p className="font-bold font-sans mb-1 text-2xl text-white">
                    DTC Motors
                </p>

                <h1 class="font-bold mb-16 text-5xl text-white md:leading-tight lg:leading-tight lg:text-6xl text-center shadow-lg">
    <span class="block">
        <span class="text-red-500">D</span>istinguished, 
        <span class="text-red-500"> T</span>rusted, and
        <span class="text-red-500"> C</span>urated.
    </span>
    
    <span class="block">Just for you.</span>
</h1>




<div className="bg-white/65 bg-opacity-75 text-gray-800 p-8 max-w-3xl mx-auto rounded-lg shadow-lg">
    <h2 className="font-bold mb-2 text-gray-900 text-xl text-center">
        Let's find your ideal car
    </h2>
    <form onSubmit={handleSubmit}>
        <div className="-mx-1 flex flex-wrap items-center mb-4">
            <div className="p-1 w-full sm:flex-1">
                <input
                    className="shadow-md border px-5 py-2 text-gray-600 w-full"
                    type="text"
                    name="carName"
                    value={filters.carName}
                    onChange={handleChange}
                    placeholder="Search for car models"
                />
            </div>
            <div className="p-1 text-right w-full sm:flex-initial sm:w-auto">
                <button type="submit"
                    className="bg-red-500 hover:bg-primary-600 inline-block px-6 py-2 text-center text-white"
                >
                    <span>Search</span>
                </button>
            </div>
        </div>
        <div className="w-full shadow p-4 px-12 mb-10 rounded-lg bg-white bg-opacity-90">
            <div className="relative">
                <div className="flex items-center justify-between mt-4">
                    <h2 className="font-bold mb-2 text-gray-900 text-xl">
                        Filter
                    </h2>
                    <button onClick={handleReset}
                        className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 text-sm font-medium rounded-md">
                        Reset Filter
                    </button>
                </div>
                <div>
                    <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 mt-4">
                        <select
                            name="model"
                            value={filters.model}
                            onChange={handleChange}
                            className="px-2 py-3 w-full md:w-36 rounded-md bg-gray-200 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                        >
                            <option value="">Any Brand</option>
                            <option value="Aston Martin">Aston Martin</option>
                            <option value="Bentley">Bentley</option>
                            <option value="BMW">BMW</option>
                            <option value="Bugatti">Bugatti</option>
                            <option value="Ferrari">Ferrari</option>
                            <option value="Lamborghini">Lamborghini</option>
                            <option value="Land Rover">Land Rover</option>
                            <option value="Maserati">Maserati</option>
                            <option value="Mercedes">Mercedes</option>
                            <option value="Porsche">Porsche</option>
                            <option value="Rolls-Royce">Rolls-Royce</option>
                            <option value="Tesla">Tesla</option>
                        </select>
                        <select
                            name="bodyType"
                            value={filters.bodyType}
                            onChange={handleChange}
                            className="px-2 py-3 w-full md:w-36 ml-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                        >
                            <option value="">Any Body Type</option>
                            <option value="Hatchback">Hatchback</option>
                            <option value="Sedan">Sedan</option>
                            <option value="SUV">SUV</option>
                            <option value="MUV">MUV</option>
                            <option value="Coupe">Coupe</option>
                            <option value="Convertible">Convertible</option>
                            <option value="Pickup Truck">Pickup Truck</option>
                            <option value="Sports Car">Sports Car</option>
                        </select>
                        <select
                            name="priceRange"
                            value={filters.priceRange}
                            onChange={handleChange}
                            className="px-1 py-3 w-full md:w-36 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                        >
                            <option value="">Any Price</option>
                            <option value={1}>&lt; RM 300,000</option>
                            <option value={2}>&lt; RM 600,000</option>
                            <option value={3}>&lt; RM 900,000</option>
                            <option value={4}>&lt; RM 1,000,000</option>
                        </select>
                        <select
                            name="yearRange"
                            value={filters.yearRange}
                            onChange={handleChange}
                            className="px-2 py-3 w-full md:w-36 ml-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                        >
                            <option value="">Any Year</option>
                            <option value={1}>&lt; 2015</option>
                            <option value={2}>2016 - 2020</option>
                            <option value={3}>2021 - {new Date().getFullYear() - 1}</option>
                            <option value={4}>{new Date().getFullYear()}</option>
                        </select>
                        <select
                            name="transmission"
                            value={filters.transmission}
                            onChange={handleChange}
                            className="px-1 py-3 w-full md:w-36 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                        >
                            <option value="">Any Transmission</option>
                            <option value="Manual">Manual</option>
                            <option value="Automatic">Automatic</option>
                        </select>
                        <select
                            name="mileage"
                            value={filters.mileage}
                            onChange={handleChange}
                            className="px-2 py-3 w-full md:w-36 ml-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                        >
                            <option value="">Any Mileage</option>
                            <option value={1}>&lt; 50,000</option>
                            <option value={2}>&lt; 100,000</option>
                            <option value={3}>&lt; 150,000</option>
                            <option value={4}>&lt; 200,000</option>
                        </select>
                        <select
                            name="color"
                            value={filters.color}
                            onChange={handleChange}
                            className="px-1 py-3 w-full md:w-36 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                        >
                            <option value="">Any Color</option>
                            <option value="Black">Black</option>
                            <option value="White">White</option>
                            <option value="Grey">Grey</option>
                            <option value="Red">Red</option>
                            <option value="Blue">Blue</option>
                            <option value="Green">Green</option>
                            <option value="Yellow">Yellow</option>
                        </select>
                        <select
                            name="center"
                            value={filters.center}
                            onChange={handleChange}
                            className="px-2 py-3 w-full md:w-36 ml-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                        >
                            <option value="">Any Center</option>
                            <option value="Kuala Lumpur">Kuala Lumpur</option>
                            <option value="Selangor">Selangor</option>
                            <option value="Penang">Penang</option>
                            <option value="Johor">Johor</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
</div>




        </section>
                {/* <section className="bg-secondary-500 poster relative text-gray-300">
                    <div className="container mx-auto pb-24 pt-50 px-4">
                        <div className="-mx-4 flex flex-wrap items-center space-y-6 lg:space-y-0">
                            <div className="px-4 w-full md:w-9/12 xl:w-7/12 2xl:w-6/12">
                                <p className="font-bold font-sans mb-1 text-2xl text-white">
                                    DTC Motors
                                </p>
                               
                                <h1 className="font-bold mb-6 text-5xl text-white md:leading-tight lg:leading-tight lg:text-6xl">
                                Distingushed,{" "}
                                    <span className="text-red-500">Trusted</span> and{" "}
                                    <span className="text-red-500">Curated.</span>
                                    <span className="text-white">Just for you.</span>
                                </h1>
                                <div className="bg-white/65 p-6 center">
                                    <h2 className="font-bold mb-2 text-gray-900 text-xl">
                                        Let's find your ideal car
                                    </h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="-mx-1 flex flex-wrap items-center">
                                            <div className="p-1 w-full sm:flex-1">
                                                <input
                                                    className="shadow-md border px-5 py-2 text-gray-600 w-full"
                                                    type="text"
                                                    name={"carName"}
                                                    value={filters.carName} onChange={handleChange}
                                                    required=""
                                                    placeholder="Search for cars model"
                                                />
                                            </div>
                                            <div className="p-1 text-right w-full sm:flex-initial sm:w-auto">
                                                <button type="submit"
                                                        className="bg-red-500 hover:bg-primary-600 inline-block px-6 py-2 text-center text-white"
                                                >
                                                    <span>Search</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="w-full md:w-full shadow p-5 rounded-lg bg-white">
                                            <div className="relative">
                                                <div className="flex items-center justify-between mt-4">
                                                    <h2 className="font-bold mb-2 text-gray-900 text-xl">
                                                        Filter
                                                    </h2>
                                                    <button onClick={handleReset}
                                                            className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 text-sm font-medium rounded-md">
                                                        Reset Filter
                                                    </button>
                                                </div>
                                                <div>
                                                    <div
                                                        className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 mt-4">
                                                        <select
                                                            name="model"
                                                            value={filters.model}
                                                            onChange={handleChange}
                                                            className="px-3 py-3 w-full rounded-md bg-gray-200 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600">
                                                            <option value="">Any Brand</option>
                                                            <option value="Aston Martin">Aston Martin</option>
                                                            <option value="Bentley">Bentley</option>
                                                            <option value="BMW">BMW</option>
                                                            <option value="Bugatti">Bugatti</option>
                                                            <option value="Ferrari">Ferrari</option>
                                                            <option value="Lamborghini">Lamborghini</option>
                                                            <option value="Land Rover">Land Rover</option>
                                                            <option value="Maserati">Maserati</option>
                                                            <option value="Mercedes">Mercedes</option>
                                                            <option value="Porsche">Porsche</option>
                                                            <option value="Rolls-Royce">Rolls-Royce</option>
                                                            <option value="Tesla">Tesla</option>
                                                        </select>
                                                        <select
                                                            name="bodyType"
                                                            value={filters.bodyType}
                                                            onChange={handleChange}
                                                            className="px-2 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600">
                                                            <option value="">Any Body Type</option>
                                                            <option value="Hatchback">Hatchback</option>
                                                            <option value="Sedan">Sedan</option>
                                                            <option value="SUV">SUV</option>
                                                            <option value="MUV">MUV</option>
                                                            <option value="Coupe">Coupe</option>
                                                            <option value="Convertable">Convertable</option>
                                                            <option value="Pickup Truck">Pickup Truck</option>
                                                            <option value="Sports Car">Sports Car</option>
                                                        </select>
                                                        <select
                                                            name="priceRange"
                                                            value={filters.priceRange}
                                                            onChange={handleChange}
                                                            className="px-3 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600">
                                                            <option value="">Any Price</option>
                                                            <option value={1}>&lt; RM 300,000</option>
                                                            <option value={2}>&lt; RM 600,000</option>
                                                            <option value={3}>&lt; RM 900,000</option>
                                                            <option value={4}>&lt; RM 1,000,000</option>
                                                        </select>
                                                        <select
                                                            name="yearRange"
                                                            value={filters.yearRange}
                                                            onChange={handleChange}
                                                            className="px-3 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600">
                                                            <option value="">Any Year</option>
                                                            <option value={1}>&lt; 2015</option>
                                                            <option value={2}>2016 - 2020</option>
                                                            <option value={3}>2021
                                                                - {new Date().getFullYear() - 1}</option>
                                                            <option value={4}>{new Date().getFullYear()}</option>
                                                        </select>
                                                        <select
                                                            name="transmission"
                                                            value={filters.transmission}
                                                            onChange={handleChange}
                                                            className="px-2 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600">
                                                            <option value="">Any Transmission</option>
                                                            <option value="Manual">Manual</option>
                                                            <option value="Automatic">Automatic</option>
                                                        </select>
                                                        <select
                                                            name="mileage"
                                                            value={filters.mileage}
                                                            onChange={handleChange}
                                                            className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600">
                                                            <option value="">Any Mileage</option>
                                                            <option value={1}>&lt; 50,000</option>
                                                            <option value={2}>&lt; 100,000</option>
                                                            <option value={3}>&lt; 150,000</option>
                                                            <option value={4}>&lt; 200,000</option>
                                                        </select>
                                                        <select
                                                            name="color"
                                                            value={filters.color}
                                                            onChange={handleChange}
                                                            className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600">
                                                            <option value="">Any Color</option>
                                                            <option value="Black">Black</option>
                                                            <option value="White">White</option>
                                                            <option value="Grey">Grey</option>
                                                            <option value="Silver">Silver</option>
                                                            <option value="Red">Red</option>
                                                            <option value="Blue">Blue</option>
                                                            <option value="Brown">Brown</option>
                                                            <option value="Gold">Gold</option>
                                                            <option value="Green">Green</option>
                                                            <option value="Orange">Orange</option>
                                                            <option value="Beige">Beige</option>
                                                            <option value="Purple">Purple</option>
                                                            <option value="Bronze">Bronze</option>
                                                        </select>
                                                        <select
                                                            name="center"
                                                            value={filters.center}
                                                            onChange={handleChange}
                                                            className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600">
                                                            <option value="">Any Center</option>
                                                            <option value="Kuala Lumpur">DTC Kuala Lumpur</option>
                                                            <option value="Kuantan">DTC Kuantan</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="App">
            <BackgroundCarousel />
        </div> */}

                <section className="py-16">
                    <div className="container mx-auto px-4">
                        <div className="-mx-4 flex flex-wrap items-center mb-6">
                            <div className="px-4 w-full md:flex-1 mt-30">
                                <h3 className="capitalize font-bold mb-4 text-4xl text-gray-900">
                                    Browse by Category
                                </h3>
                                <div className="bg-red-500 mb-6 pb-1 w-2/12"/>
                            </div>
                        </div>
                        <div className="-mx-3 flex flex-wrap justify-center">
                            <div className="p-3 w-full md:w-6/12 lg:w-3/12">
                                <a
                                    onClick={() => handleCategoryClick('Hatchback')}
                                    className="bg-white block border group hover:text-gray-500 shadow-md text-gray-900"
                                >
                                    <img
                                        src="https://media.ed.edmunds-media.com/lexus/ct-200h/2012/oem/2012_lexus_ct-200h_4dr-hatchback_premium_fq_oem_3_1600.jpg"
                                        className="group-hover:opacity-90 w-full"
                                        alt="..."
                                        width={600}
                                        height={450}
                                    />
                                    <div className="px-6 py-4">
                                        <h4 className="font-bold text-xl">Hatchback</h4>
                                    </div>
                                </a>
                            </div>
                            <div className="p-3 w-full md:w-6/12 lg:w-3/12">
                                <a
                                    onClick={() => handleCategoryClick('Sedan')}
                                    className="bg-white block border group hover:text-gray-500 shadow-md text-gray-900"
                                >
                                    <img
                                        src="https://paultan.org/image/2013/12/2014-ECOTY-Tesla-Model-S.jpg"
                                        className="group-hover:opacity-90 w-full"
                                        alt="..."
                                        width={600}
                                        height={450}
                                    />
                                    <div className="px-6 py-4">
                                        <h4 className="font-bold text-xl">Sedan</h4>
                                    </div>
                                </a>
                            </div>
                            <div className="p-3 w-full md:w-6/12 lg:w-3/12">
                                <a
                                    onClick={() => handleCategoryClick('SUV')}
                                    className="bg-white block border group hover:text-gray-500 shadow-md text-gray-900"
                                >
                                    <img
                                        src="https://evault.honda.com.my/pixelvault/2023-12/956e1b35c87ba3352f3fafb00042cac7d8ef577811287.jpg"
                                        className="group-hover:opacity-90 w-full"
                                        alt="..."
                                        width={600}
                                        height={450}
                                    />
                                    <div className="px-6 py-4">
                                        <h4 className="font-bold text-xl">SUV</h4>
                                    </div>
                                </a>
                            </div>
                            <div className="p-3 w-full md:w-6/12 lg:w-3/12">
                                <a
                                    onClick={() => handleCategoryClick('MUV')}
                                    className="bg-white block border group hover:text-gray-500 shadow-md text-gray-900"
                                >
                                    <img
                                        src="https://d2m3nfprmhqjvd.cloudfront.net/blog/20220228132412/kia-carnival-1160x748.jpg"
                                        className="group-hover:opacity-90 w-full"
                                        alt="..."
                                        width={600}
                                        height={450}
                                    />
                                    <div className="px-6 py-4">
                                        <h4 className="font-bold text-xl">MUV</h4>
                                    </div>
                                </a>
                            </div>
                            <div className="p-3 w-full md:w-6/12 lg:w-3/12">
                                <a
                                    onClick={() => handleCategoryClick('Coupe')}
                                    className="bg-white block border group hover:text-gray-500 shadow-md text-gray-900"
                                >
                                    <img
                                        src="https://robbreport.com.my/wp-content/uploads/2023/08/FEAT_Dream-Machines-Rolls-Royce-Spectre-LEAD.jpg"
                                        className="group-hover:opacity-90 w-full"
                                        alt="..."
                                        width={600}
                                        height={450}
                                    />
                                    <div className="px-6 py-4">
                                        <h4 className="font-bold text-xl">Coupe</h4>
                                    </div>
                                </a>
                            </div>
                            <div className="p-3 w-full md:w-6/12 lg:w-3/12">
                                <a
                                    onClick={() => handleCategoryClick('Convertable')}
                                    className="bg-white block border group hover:text-gray-500 shadow-md text-gray-900"
                                >
                                    <img
                                        src="https://www.cnet.com/a/img/resize/447f6f4c32420cb94ef76aab5d8130f76df781f3/hub/2020/07/16/faadaf57-d364-4a05-9c02-a0fc32ffa7f9/ogi1-2020-porsche-911-carrera-s-cabriolet-review-001.jpg?auto=webp&fit=crop&height=675&width=1200"
                                        className="group-hover:opacity-90 w-full"
                                        alt="..."
                                        width={600}
                                        height={450}
                                    />
                                    <div className="px-6 py-4">
                                        <h4 className="font-bold text-xl">Convertable</h4>
                                    </div>
                                </a>
                            </div>
                            <div className="p-3 w-full md:w-6/12 lg:w-3/12">
                                <a
                                    onClick={() => handleCategoryClick('Pickup Truck')}
                                    className="bg-white block border group hover:text-gray-500 shadow-md text-gray-900"
                                >
                                    <img
                                        src="https://www.forbes.com/wheels/wp-content/uploads/2021/01/2020_Chevrolet_Silverado_Midnight_Featured.jpg"
                                        className="group-hover:opacity-90 w-full"
                                        alt="..."
                                        width={600}
                                        height={450}
                                    />
                                    <div className="px-6 py-4">
                                        <h4 className="font-bold text-xl">Pickup Truck</h4>
                                    </div>
                                </a>
                            </div>
                            <div className="p-3 w-full md:w-6/12 lg:w-3/12">
                                <a
                                    onClick={() => handleCategoryClick('Sports Car')}
                                    className="bg-white block border group hover:text-gray-500 shadow-md text-gray-900"
                                >
                                    <img
                                        src="https://i.ytimg.com/vi/kI_0E5uiqEM/maxresdefault.jpg"
                                        className="group-hover:opacity-90 w-full"
                                        alt="..."
                                        width={600}
                                        height={450}
                                    />
                                    <div className="px-6 py-4">
                                        <h4 className="font-bold text-xl">Sports Car</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="bg-gray-50 py-24">
                    <div className="container mx-auto px-4">
                        <div className="-mx-4 flex flex-wrap items-center mb-6">
                            <div className="px-4 w-full md:w-10/12">
                                <h2 className="font-medium mb-1 text-red-500 text-xl">
                                    Our Top Cars
                                </h2>
                                <h3 className="capitalize font-bold mb-4 text-4xl text-gray-900">
                                    Cars for all your needs
                                </h3>
                                <div className="bg-red-500 mb-6 pb-1 w-2/12"/>
                            </div>
                        </div>
                        <div className="-mx-3 flex flex-wrap justify-center mb-12">
                            {stocks.slice(0, 6).map((stock) => (
                                <div key={stock._id} className="p-3 w-full md:w-6/12 lg:w-4/12">
                                    <Link to={`/details/${stock._id}`}>
                                        <div className="bg-white border shadow-md text-gray-500">
                                            <div
                                                className="relative w-full h-72 overflow-hidden">
                                                <img
                                                    src={stock.gallery.overall}
                                                    className="hover:opacity-90 w-full h-full object-cover" // object-cover should be always on to maintain aspect ratio
                                                    alt={stock.carName}
                                                    width={600}
                                                    height={450}
                                                />
                                                {stock.salesProgressStatus === "In Progress" && (
                                                    <div
                                                        className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black opacity-70">
                                                        <span
                                                            className="text-white text-3xl font-bold">Purchase Pending</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="p-6">
                                                <h4 className="font-bold mb-2 text-gray-900 text-xl">
                                                    <a className="hover:text-gray-500">
                                                        {stock.brand} {stock.carName}
                                                    </a>
                                                </h4>
                                                <div className="flex-wrap inline-flex items-center py-1 space-x-2">
                                                    <div
                                                        className="rounded-full border border-primary-500 inline-block px-6 py-1 text-red-500"
                                                    >
                                                        {stock.type}
                                                    </div>
                                                    {stock.discountedValue ? (
                                                        <div
                                                            className="rounded-full bg-red-500 border border-primary-500 inline-block px-6 py-1 text-white"
                                                        >
                                                            Great Deals
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                </div>

                                                <div className="flex items-center">
                                                    {stock.discountedValue ? (
                                                        <>
                                                            <p className="font-bold text-gray-500 line-through">RM {stock.value.toLocaleString()}</p>
                                                            <p className="font-bold text-gray-900">RM {stock.discountedValue.toLocaleString()}</p>
                                                        </>
                                                    ) : (
                                                        <p className="font-bold text-gray-900">RM {stock.value.toLocaleString()}</p>
                                                    )}
                                                    {/*<div className="relative">*/}
                                                    {/*    /!* Tooltip trigger *!/*/}
                                                    {/*    <button*/}
                                                    {/*        onMouseOver={() => showTooltip(stock._id)}*/}
                                                    {/*        onMouseOut={() => hideTooltip(stock._id)}*/}
                                                    {/*        onFocus={() => showTooltip(stock._id)}*/}
                                                    {/*        onBlur={() => hideTooltip(stock._id)}*/}
                                                    {/*        className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"*/}
                                                    {/*        aria-haspopup="true"*/}
                                                    {/*    >*/}
                                                    {/*        <svg*/}
                                                    {/*            className="icon icon-tabler icon-tabler-info-circle h-6 w-6 text-gray-500"*/}
                                                    {/*            viewBox="0 0 24 24"*/}
                                                    {/*            strokeWidth="1.5"*/}
                                                    {/*            stroke="currentColor"*/}
                                                    {/*            fill="none"*/}
                                                    {/*            strokeLinecap="round"*/}
                                                    {/*            strokeLinejoin="round"*/}
                                                    {/*        >*/}
                                                    {/*            <circle cx={12} cy={12} r={9}/>*/}
                                                    {/*            <line x1={12} y1={8} x2={12.01} y2={8}/>*/}
                                                    {/*            <polyline points="11 12 12 12 12 16 13 16"/>*/}
                                                    {/*        </svg>*/}
                                                    {/*    </button>*/}

                                                    {/*    /!* Tooltip body *!/*/}
                                                    {/*     {tooltipVisible[stock._id] && (*/}
                                                    {/*     <div*/}
                                                    {/*     className="absolute z-50 left-0 ml-10 w-64 p-2 bg-gray-800 text-white rounded shadow-lg">*/}
                                                    {/*     <p className="text-sm font-bold">Discount Price</p>*/}
                                                    {/*     <p className="text-xs">RM 6000 Off</p>*/}
                                                    {/*     <p className="text-xs">Estimated monthly payments are*/}
                                                    {/*     based on:</p>*/}
                                                    {/*     <p className="text-xs">10% Down Payment</p>*/}
                                                    {/*     <p className="text-xs">Annual Interest Rate 3.5%</p>*/}
                                                    {/*     <p className="text-xs">9 Year Loan Term</p>*/}
                                                    {/*     </div>*/}
                                                    {/*     )}*/}

                                                    {/*</div>*/}
                                                </div>

                                                <div className="flex items-center justify-between">
                                                    <div className="inline-flex items-center py-1 space-x-1">
                                                        <span>{stock.specs.mileage} KM</span>
                                                        <svg
                                                            className="h-8 w-8 text-red-500"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            {" "}
                                                            <path stroke="none" d="M0 0h24v24H0z"/>
                                                            {" "}
                                                            <line x1={12} y1={18} x2="12.01" y2={18}/>
                                                        </svg>
                                                        <span>{stock.specs.transmissionType}</span>
                                                        <svg
                                                            className="h-8 w-8 text-red-500"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            {" "}
                                                            <path stroke="none" d="M0 0h24v24H0z"/>
                                                            {" "}
                                                            <line x1={12} y1={18} x2="12.01" y2={18}/>
                                                        </svg>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                                            />
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                                                            />
                                                        </svg>
                                                        <span>{stock.stockLocation}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            ))}
                        </div>

                        {/*view all button*/}

                        <div className="text-center">
                            <Link to={`/list`}
                                  className="bg-red-500 hover:bg-primary-600 inline-block mt-4 px-6 py-2 text-white"
                            >
                                View All Cars
                            </Link>
                        </div>
                    </div>
                </section>

                <section className="bg-gray-900 py-24">
                    <div className="container mx-auto px-4">
                        <div className="-mx-4 flex flex-wrap items-center mb-6">
                            <div className="px-4 w-full md:w-10/12">
                                <h3 className="capitalize font-bold mb-4 text-4xl text-white">
                                    Most Recent Sold Cars
                                </h3>
                                <div className="bg-red-500 mb-6 pb-1 w-2/12"/>
                            </div>
                        </div>
                        <div className="-mx-3 flex flex-wrap justify-center mb-12">
                            {soldStocks.slice(0, 3).map((stock) => (
                                <div key={stock._id} className="p-3 w-full md:w-6/12 lg:w-4/12">
                                    <div className="bg-white border shadow-md text-gray-500">
                                        <img
                                            src={stock.gallery.overall}
                                            className="w-full h-60"
                                            alt={stock.carName}
                                            width={600}
                                            height={450}
                                        />
                                        <div className="p-6">
                                            <h4 className="font-bold mb-2 text-gray-900 text-xl">
                                                <a className="hover:text-gray-500">
                                                    {stock.brand} {stock.carName}
                                                </a>
                                            </h4>
                                            <div className="flex-wrap inline-flex items-center py-1 space-x-2">
                                                <div
                                                    className="rounded-full border border-primary-500 inline-block px-6 py-1 text-red-500"
                                                >
                                                    {stock.type}
                                                </div>
                                                {stock.discountedValue ? (
                                                    <div
                                                        className="rounded-full bg-red-500 border border-primary-500 inline-block px-6 py-1 text-white"
                                                    >
                                                        Great Deals
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}

                                            </div>

                                            <div className="flex items-center">
                                                {stock.discountedValue ? (
                                                    <>
                                                        <p className="font-bold text-gray-500 line-through">RM {stock.value.toLocaleString()}</p>
                                                        <p className="font-bold text-gray-900">RM {stock.discountedValue.toLocaleString()}</p>
                                                    </>
                                                ) : (
                                                    <p className="font-bold text-gray-900">RM {stock.value.toLocaleString()}</p>
                                                )}
                                                {/*<div className="relative">*/}
                                                {/*    /!* Tooltip trigger *!/*/}
                                                {/*    <button*/}
                                                {/*        onMouseOver={() => showTooltip(stock._id)}*/}
                                                {/*        onMouseOut={() => hideTooltip(stock._id)}*/}
                                                {/*        onFocus={() => showTooltip(stock._id)}*/}
                                                {/*        onBlur={() => hideTooltip(stock._id)}*/}
                                                {/*        className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"*/}
                                                {/*        aria-haspopup="true"*/}
                                                {/*    >*/}
                                                {/*        <svg*/}
                                                {/*            className="icon icon-tabler icon-tabler-info-circle h-6 w-6 text-gray-500"*/}
                                                {/*            viewBox="0 0 24 24"*/}
                                                {/*            strokeWidth="1.5"*/}
                                                {/*            stroke="currentColor"*/}
                                                {/*            fill="none"*/}
                                                {/*            strokeLinecap="round"*/}
                                                {/*            strokeLinejoin="round"*/}
                                                {/*        >*/}
                                                {/*            <circle cx={12} cy={12} r={9}/>*/}
                                                {/*            <line x1={12} y1={8} x2={12.01} y2={8}/>*/}
                                                {/*            <polyline points="11 12 12 12 12 16 13 16"/>*/}
                                                {/*        </svg>*/}
                                                {/*    </button>*/}

                                                {/*    /!* Tooltip body *!/*/}
                                                {/*     {tooltipVisible[stock._id] && (*/}
                                                {/*     <div*/}
                                                {/*     className="absolute z-50 left-0 ml-10 w-64 p-2 bg-gray-800 text-white rounded shadow-lg">*/}
                                                {/*     <p className="text-sm font-bold">Discount Price</p>*/}
                                                {/*     <p className="text-xs">RM 6000 Off</p>*/}
                                                {/*     <p className="text-xs">Estimated monthly payments are*/}
                                                {/*     based on:</p>*/}
                                                {/*     <p className="text-xs">10% Down Payment</p>*/}
                                                {/*     <p className="text-xs">Annual Interest Rate 3.5%</p>*/}
                                                {/*     <p className="text-xs">9 Year Loan Term</p>*/}
                                                {/*     </div>*/}
                                                {/*     )}*/}

                                                {/*</div>*/}
                                            </div>

                                            <div className="flex items-center justify-between">
                                                <div className="inline-flex items-center py-1 space-x-1">
                                                    <span>{stock.specs.mileage} KM</span>
                                                    <svg
                                                        className="h-8 w-8 text-red-500"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={2}
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        {" "}
                                                        <path stroke="none" d="M0 0h24v24H0z"/>
                                                        {" "}
                                                        <line x1={12} y1={18} x2="12.01" y2={18}/>
                                                    </svg>
                                                    <span>{stock.specs.transmissionType}</span>
                                                    <svg
                                                        className="h-8 w-8 text-red-500"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={2}
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        {" "}
                                                        <path stroke="none" d="M0 0h24v24H0z"/>
                                                        {" "}
                                                        <line x1={12} y1={18} x2="12.01" y2={18}/>
                                                    </svg>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                                        />
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                                                        />
                                                    </svg>
                                                    <span>{stock.stockLocation}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            ))}
                        </div>
                    </div>
                </section>

                {/*<Newsletter/>*/}
                <DownloadSection/>
            </main>
            <Footer/>
        </>
    );
};

export default WebsiteIndex;
