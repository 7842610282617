import React from "react";
import {Link} from "react-router-dom";


const Footer = () => {
    return (
        <>
            <footer className="bg-black pt-12 text-gray-300">
                <div className="container mx-auto px-4 relative">
                    <div className="flex flex-wrap -mx-4">
                        <div className="p-4 w-full lg:w-4/12">
                            <a
                                href="#"
                                className="font-bold font-sans hover:text-opacity-90 inline-flex items-center leading-none mb-4 space-x-2 text-3xl bg-red-500 uppercase"
                            >
                                <img
                                    src="https://lh3.googleusercontent.com/p/AF1QipMxXYqEuFhTZKZNxLc5_bFB97nDCEJFYzel1Wkp=w1080-h608-p-no-v0"
                                    alt="Car Image" className="w-full h-19 rounded"/>

                                {/* <span>DTC Motors</span> */}
                                {" "}
                            </a>
                            <ul className="mb-4 space-y-1">
                                <li>
                                    Lot 253, Jalan Ampang Hilir, Desa Pahlawan, 50450 Kuala Lumpur,
                                    Wilayah Persekutuan Kuala Lumpur
                                </li>
                                <li>
                                    <a href="#" className="hover:text-gray-400 text-white">
                                        + 03-2144 3110
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="mailto:hello@fafo.com"
                                        className="hover:text-gray-400 text-white"
                                    >
                                        dtcmsb.2020@gmail.com
                                    </a>
                                </li>
                            </ul>
                            <div className="flex-wrap inline-flex space-x-3">
                                <a
                                    href="https://www.facebook.com/dtclifestyleHQ/"
                                    aria-label="facebook"
                                    className="hover:text-gray-400"
                                >
                                    {" "}
                                    <svg viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
                                        <path
                                            d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"/>
                                    </svg>
                                </a>
                                <a
                                    href="https://www.instagram.com/dtcmotors/?hl=en"
                                    aria-label="instagram"
                                    className="hover:text-gray-400"
                                >
                                    {" "}
                                    <svg viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
                                        <path
                                            d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"/>
                                    </svg>
                                </a>
                                <a href="https://www.youtube.com/@dtcmotors/featured" aria-label="youtube"
                                   className="hover:text-gray-400">
                                    <svg viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
                                        <path
                                            d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z"/>
                                    </svg>
                                </a>
                                <a href="https://www.tiktok.com/@dtc_carlifestyle?is_from_webapp=1&sender_device=pc"
                                   aria-label="tiktok" className="hover:text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="currentColor"
                                         className="h-5 w-5">
                                        <path
                                            d="M38.4 21.68V16c-2.66 0-4.69-.71-6-2.09a8.9 8.9 0 0 1-2.13-5.64v-.41l-5.37-.13V30.8a5 5 0 1 1-3.24-5.61v-5.5a10.64 10.64 0 0 0-1.7-.14 10.36 10.36 0 1 0 10.36 10.36 10.56 10.56 0 0 0-.08-1.27v-9.15a14.48 14.48 0 0 0 8.16 2.19Z"></path>
                                    </svg>
                                </a>


                            </div>
                        </div>
                        <div className="p-4 w-full sm:w-6/12 md:flex-1 lg:w-3/12">
                            <h2 className="font-bold text-color3-500 text-xl">Company</h2>
                            <hr className="border-gray-600 inline-block mb-6 mt-4 w-3/12"/>
                            <ul>
                                <li className="mb-4">
                                    <Link to="/list" className="hover:text-gray-400">
                                        All Cars
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="/list-deals" className="hover:text-gray-400">
                                        Great Deals
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="/location" className="hover:text-gray-400">
                                        Location
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="/about" className="hover:text-gray-400">
                                        About Us
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="/article" className="hover:text-gray-400">
                                        Article
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="p-4 w-full sm:w-6/12 md:flex-1 lg:w-3/12">
                            <h2 className="font-bold text-color3-500 text-xl">Vehicles</h2>
                            <hr className="border-gray-600 inline-block mb-6 mt-4 w-3/12"/>
                            <ul>
                                <li className="mb-4">

                                    <Link to={'/list'} className="hover:text-gray-400">

                                        Hatchback
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to={`/list`} className="hover:text-gray-400">
                                        Sedan
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to={`/list`} className="hover:text-gray-400">
                                        SUV
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to={`/list`} className="hover:text-gray-400">
                                        MUV
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to={`/list`} className="hover:text-gray-400">
                                        Coupe
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to={`/list`} className="hover:text-gray-400">
                                        Convertible
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to={`/list`} className="hover:text-gray-400">
                                        Pickup Truck
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to={`/list`} className="hover:text-gray-400">
                                        Sports Car
                                    </Link>
                                </li>

                            </ul>
                        </div>
                        <div className="p-4 w-full md:w-5/12 lg:w-4/12">
                            <h2 className="font-bold text-color3-500 text-xl">Top Cities</h2>
                            <hr className="border-gray-600 inline-block mb-6 mt-4 w-3/12"/>
                            <div className="-mx-4 flex flex-wrap">
                                <div className="pb-4 px-4 w-full sm:w-6/12">
                                    <ul>
                                        <li className="mb-4">
                                            <Link to={'/location'} className="hover:text-gray-400">
                                                Kuala Lumpur
                                            </Link>
                                        </li>
                                        <li className="mb-4">
                                            <Link to={'/location'} className="hover:text-gray-400">
                                                Pahang
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-4">
                        <hr className="mb-4 opacity-25"/>
                        <div className="flex flex-wrap -mx-4  items-center">
                            <div className="px-4 py-2 w-full md:flex-1">
                                <p>© {new Date().getFullYear()}. All Rights Reserved - DTC Motors</p>
                            </div>
                            <div className="px-4 py-2 w-full md:w-auto">
                                <Link to={`/privacy-policy`}>
                                    <a className="hover:text-gray-400">
                                        Privacy Policy
                                    </a>
                                </Link>
                                {" "}
                                |
                                {" "}
                                <Link to={`/terms`}>
                                <a className="hover:text-gray-400">
                                    Terms of Use
                                </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
