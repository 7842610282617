import './tailwind_theme/tailwind.css';

import Header from "../component/header";
import Footer from "../component/footer";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../config/config";
import { useParams } from "react-router-dom";

const DetailsPage = () => {
    let { id } = useParams();


    const [activeTab, setActiveTab] = useState(0);

    const [stock, setStock] = useState({});

    const [selectedImage, setSelectedImage] = useState('');

    // const handleTabChange = (index) => {
    //     setActiveTab(index);
    // };

    useEffect(() => {
        fetchStockDetails();
    }, [id]);

    const fetchStockDetails = async () => {
        try {
            const response = await axios.get(`${API}/directory/stock/${id}`);
            const stock = response.data.data;
            setStock(stock);

            // Set default image to 'overall' if available or the first available in the gallery
            setSelectedImage(response.data.data.gallery?.overall || Object.values(response.data.data.gallery || {}).find(url => url));

        } catch (error) {
            console.error("Error fetching stock details:", error);
        }
    };

    if (!stock) {
        return <div>Loading...</div>;
    }

    const filteredGallery = stock.gallery ? Object.entries(stock.gallery).reduce((acc, [key, value]) => {
        if (value && key !== '_id') {  // Exclude '_id' and ensure value is not empty
            acc[key] = value;
        }
        return acc;
    }, {}) : {};

    const galleryImages = Object.values(filteredGallery);

    console.log(galleryImages);

    const handleImageChange = (newImage) => {
        setSelectedImage(newImage);
    };

    const handleNavigation = (direction) => {
        const currentIndex = galleryImages.indexOf(selectedImage);
        const nextIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1;
        if (nextIndex >= 0 && nextIndex < galleryImages.length) {
            setSelectedImage(galleryImages[nextIndex]);
        }
    };

    return (
        <>
            <Header />
            <main>

                <section className="font-poppins py-10 dark:bg-gray-800">

                    <div className="mx-auto max-w-6xl px-4">
                        <div className="-mx-4 mb-24 flex flex-wrap">
                            <div className="mb-8 w-full px-4 md:mb-0 md:w-1/2">
                                <div className="sticky top-0 overflow-hidden">
                                    <div className="relative mb-6 lg:mb-10 lg:h-96">
                                        {galleryImages.length > 1 && (
                                            <button
                                                className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10"
                                                onClick={() => handleNavigation('left')}
                                                disabled={galleryImages.indexOf(selectedImage) === 0}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className="bi bi-chevron-left h-5 w-5 text-blue-500 dark:text-blue-200"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        )}
                                        <img
                                            className="w-full object-contain lg:h-full"
                                            src={selectedImage || ""}
                                            alt={stock.carName || 'Car image'}
                                        />
                                        {galleryImages.length > 1 && (
                                            <button
                                                className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10"
                                                onClick={() => handleNavigation('right')}
                                                disabled={galleryImages.indexOf(selectedImage) === galleryImages.length - 1}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className="bi bi-chevron-right h-5 w-5 text-blue-500 dark:text-blue-200"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        )}
                                    </div>
                                    {galleryImages.length > 0 && (
                                        <div className="-mx-2 hidden flex-wrap md:flex">
                                            {galleryImages.map((url, index) => (
                                                <div key={index} className="w-1/2 p-2 sm:w-1/4">
                                                    <button
                                                        className="block w-full border border-gray-200 hover:border-blue-400 dark:border-gray-700 dark:hover:border-blue-300"
                                                        onClick={() => handleImageChange(url)}
                                                    >
                                                        <img className="w-full object-contain lg:h-28" src={url}
                                                            alt={`Gallery image ${index + 1}`} />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="w-full px-4 md:w-1/2">
                                <div className="lg:pl-20">
                                    <div className="mb-0">
                                        {stock.discountedValue ? (
                                            <div
                                                className="rounded-full bg-red-500 border border-primary-500 inline-block px-6 py-1 text-white"
                                            >
                                                Great Deals
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {stock.salesProgressStatus === "In Progress" ? (
                                            <div
                                                className="rounded-full  border border-primary-500 inline-block px-6 py-1 bg-red-500"
                                            >
                                                Purchase Pending
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <h2 className="mb-6 mt-6 max-w-xl text-2xl font-bold leading-loose tracking-wide text-gray-700 md:text-2xl dark:text-gray-300">
                                            {stock.brand} {stock.carName}
                                        </h2>
                                        {stock.discountedValue ? (
                                            <>
                                                <p className="inline-block text-2xl font-semibold text-gray-700 dark:text-gray-400 line-through">
                                                    <span>RM {stock.value}</span>
                                                </p>
                                                <p className="inline-block ml-2 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                                                    <span>RM {stock.discountedValue}</span>
                                                </p>
                                            </>
                                        ) : (
                                            <p className="inline-block text-2xl font-semibold text-gray-700 dark:text-gray-200">
                                                <span>RM {stock.value}</span>
                                            </p>
                                        )}
                                    </div>
                                    <div className="mb-6 mt-4 ml-0">
                                        <span className="text-base text-gray-600 dark:text-gray-400">
                                            Car Location
                                        </span>
                                        <p className="mt-2 text-2xl font-bold text-blue-500 dark:text-blue-200">
                                            DTC {stock.stockLocation}
                                        </p>
                                        <p className="text-base mt-6 text-gray-600 dark:text-gray-400">
                                            Operation hours
                                        </p>
                                        <p className="mt-2 text-xl font-bold text-blue-500 dark:text-blue-200">
                                            9.00a.m - 6.00p.m (Monday - Saturday)
                                        </p>
                                    </div>
                                    <div className="mb-6 mt-4 ml-0 ">

                                        <span className="text-base mt-6 text-gray-600 dark:text-gray-400">
                                            Details
                                        </span>
                                        <p className="mt-2 text-lg font-bold text-blue-500 dark:text-blue-200">
                                            All Prices Inclusive
                                        </p>
                                        <p className="mt-2 text-lg font-bold text-blue-500 dark:text-blue-200">
                                            Road tax
                                        </p>
                                        <p className="mt-2 text-lg font-bold text-blue-500 dark:text-blue-200">
                                            1 year warranty
                                        </p>
                                        <p className="mt-2 text-lg font-bold text-blue-500 dark:text-blue-200">
                                            Title Transfer Fee
                                        </p>
                                        <p className="mt-2 text-lg font-bold text-blue-500 dark:text-blue-200">
                                            Puspakom Inspection Fee
                                        </p>
                                        <p className="mt-2 text-lg font-bold text-blue-500 dark:text-blue-200">
                                            Loan Process Fees
                                        </p>
                                    </div>
                                    {stock.salesProgressStatus !== "In Progress" ? (
                                        <div className="mb-4 pt-6 lg:mb-0">
                                            <a
                                                href="https://wa.me/601116875642"
                                                className="w-full rounded-xl border border-blue-600 bg-blue-100 px-4 py-3 text-center text-blue-600 hover:bg-blue-600 hover:text-gray-100 lg:w-1/2 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-400 dark:hover:bg-gray-900"
                                            >
                                                Book Now
                                            </a>
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-6 mt-4 border-b border-t border-gray-200 py-6 dark:border-gray-700">
                        <div className="mx-6 mb-10 mt-5">
                            <h2 className="mb-6 text-lg text-center font-bold text-gray-500 dark:text-gray-400">
                                Car Overview
                            </h2>
                            <div className="rounded-xl bg-gray-300 dark:bg-gray-700">
                                <div className="p-3 lg:p-5">
                                    <div className="rounded-xl bg-gray-50 p-2 lg:p-6 dark:bg-gray-800">
                                        <div className="flex flex-wrap justify-center gap-x-10 gap-y-4">
                                            <div className="pt-6 mb-4 w-60">
                                                <div className="flex">
                                                    <span className="mr-3 text-gray-500 dark:text-gray-400">
                                                        <svg
                                                            className="h-8 w-8 text-red-500"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <path d="M5.5 5h13a1 1 0 0 1 0.5 1.5L14 12L14 19L10 16L10 12L5 6.5a1 1 0 0 1 0.5 -1.5" />
                                                        </svg>
                                                        {" "}
                                                    </span>
                                                    <div>
                                                        <p className="mb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                            Fuel Type
                                                        </p>
                                                        <h2 className="text-base font-semibold text-gray-700 dark:text-gray-400">
                                                            {stock.specs?.fuelType || ""}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pt-6 mb-4 w-60">
                                                <div className="flex">
                                                    <span className="mr-3 text-gray-500 dark:text-gray-400">
                                                        <svg
                                                            className="h-8 w-8 text-red-500"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <circle cx={9} cy={7} r={4} />
                                                            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                                            <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                                                        </svg>
                                                        {" "}
                                                    </span>
                                                    <div>
                                                        <p className="mb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                            Seating
                                                        </p>
                                                        <h2 className="text-base font-semibold text-gray-700 dark:text-gray-400">
                                                            {stock.specs?.seatNo || ""}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" pt-6 mb-4 w-60 lg:mb-0">
                                                <div className="flex">
                                                    <span className="mr-3 text-gray-500 dark:text-gray-400">
                                                        <svg
                                                            className="h-8 w-8 text-red-500"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <circle cx={12} cy={7} r={4} />
                                                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                        </svg>
                                                    </span>
                                                    <div>
                                                        <p className="mb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                            Registration Type
                                                        </p>
                                                        <h2 className="text-base font-semibold text-gray-700 dark:text-gray-400">
                                                            Personal
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pt-6mb-4 w-60 lg:mb-0">
                                                <div className="flex">
                                                    <span className="mr-3 text-gray-500 dark:text-gray-400">
                                                        <svg
                                                            className="h-8 w-8 text-red-500"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <circle cx={8} cy={15} r={4} />
                                                            <line x1="10.85" y1="12.15" x2={19} y2={4} />
                                                            <line x1={18} y1={5} x2={20} y2={7} />
                                                            <line x1={15} y1={8} x2={17} y2={10} />
                                                        </svg>
                                                    </span>
                                                    <div>
                                                        <p className="mb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                            Spare Key
                                                        </p>
                                                        <h2 className="text-base font-semibold text-gray-700 dark:text-gray-400">
                                                            {stock.specs?.spareKey || ""}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-4 w-60 lg:mb-0">
                                                <div className="flex">
                                                    <span className="mr-3 text-gray-500 dark:text-gray-400">
                                                        <svg
                                                            className="h-8 w-8 text-red-500"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <path d="M19 3h-4a2 2 0 0 0 -2 2v12a4 4 0 0 0 8 0v-12a2 2 0 0 0 -2 -2" />
                                                            <path d="M13 7.35l-2 -2a2 2 0 0 0 -2.828 0l-2.828 2.828a2 2 0 0 0 0 2.828l 9 9" />
                                                            <path d="M7.3 13h-2.3a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h12" />
                                                            <line x1={17} y1={17} x2={17} y2="17.01" />
                                                        </svg>
                                                    </span>
                                                    <div>
                                                        <p className="mb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                            Color
                                                        </p>
                                                        <h2 className="text-base font-semibold text-gray-700 dark:text-gray-400">
                                                            {stock.specs?.color || ""}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-4 w-60 lg:mb-0">
                                                <div className="flex">
                                                    <span className="mr-3 text-gray-500 dark:text-gray-400">
                                                        <svg
                                                            className="h-8 w-8 text-red-500"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <rect x={4} y={5} width={16} height={16} rx={2} />
                                                            <line x1={16} y1={3} x2={16} y2={7} />
                                                            <line x1={8} y1={3} x2={8} y2={7} />
                                                            <line x1={4} y1={11} x2={20} y2={11} />
                                                            <line x1={11} y1={15} x2={12} y2={15} />
                                                            <line x1={12} y1={15} x2={12} y2={18} />
                                                        </svg>
                                                    </span>
                                                    <div>
                                                        <p className="mb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                            Year Make
                                                        </p>
                                                        <h2 className="text-base font-semibold text-gray-700 dark:text-gray-400">
                                                            {stock.specs?.yearMake || ""}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-4 w-60 lg:mb-0">
                                                <div className="flex">
                                                    <span className="mr-3 text-gray-500 dark:text-gray-400">
                                                        <svg
                                                            className="h-8 w-8 text-red-500"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <circle cx={7} cy={17} r={2} />
                                                            <circle cx={17} cy={17} r={2} />
                                                            <path d="M5 17h-2v-6l2-5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" />
                                                        </svg>
                                                    </span>
                                                    <div>
                                                        <p className="mb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                            Current Mileage
                                                        </p>
                                                        <h2 className="text-base font-semibold text-gray-700 dark:text-gray-400">
                                                            {stock.specs?.mileage || "0"}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-4 w-60 lg:mb-0">
                                                <div className="flex">
                                                    <span className="mr-3 text-gray-500 dark:text-gray-400">
                                                        <svg
                                                            className="h-8 w-8 text-red-500"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <path d="M5 12l5 5l10 -10" />
                                                        </svg>
                                                        {" "}
                                                    </span>
                                                    <div>
                                                        <p className="mb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                            Manufacturer's Warranty
                                                        </p>
                                                        <h2 className="text-base font-semibold text-gray-700 dark:text-gray-400">
                                                            {stock.specs?.warranty || ""}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 className="mb-2 text-lg text-center flex justify-center items-center font-bold text-gray-500 dark:text-gray-400">
                        Car Details
                    </h2>
                    <div className="flex justify-center items-center">
                        <div className="w-full max-w-4xl bg-transparent rounded-lg">
                            {activeTab === 0 && (
                                <div className="flex justify-center">
                                    <div className="mb-6 flex flex-wrap items-stretch justify-center sm:justify-start">
                                        <div className="mb-6 mt-4 ml-2 flex-1">
                                            <div className="dark:bg-gray-700 bg-opacity-60 rounded p-4 h-full">
                                                <span className="text-xl justify-center text-gray-600 dark:text-gray-400">
                                                    Transmission Type
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.transmissionType || ""}
                                                </p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Total Gear
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.totalGear || ""}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="mb-6 mt-4 ml-2 sm:ml-20 flex-1">
                                            <div className="dark:bg-gray-700 bg-opacity-60 rounded p-4 h-full">
                                                <span className="text-xl mt-6 text-gray-600 dark:text-gray-400">
                                                    Installation
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.installation || ""}
                                                </p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Number of chairs
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.seatNo || ""}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="mb-6 mt-4 ml-2 sm:ml-20 flex-1">
                                            <div className="dark:bg-gray-700 bg-opacity-60 rounded p-4 h-full">
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Drivetrain
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.drivetrain || ""}
                                                </p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Door
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.doorNo || ""}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {activeTab === 1 && (
                                <div className="mb-6 flex flex-wrap items-stretch justify-center sm:justify-start">
                                    <div className="mb-6 mt-4 ml-2 flex-1">
                                        <div className="dark:bg-gray-700 bg-opacity-75 rounded p-4 h-full">
                                            <span className="text-xl justify-center text-gray-600 dark:text-gray-400">
                                                Transmission Type
                                            </span>
                                            <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                {stock.specs?.transmissionType || ""}
                                            </p>
                                            <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                Total Gear
                                            </span>
                                            <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                {stock.specs?.totalGear || ""}
                                            </p>
                                            <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                Drivetrain
                                            </span>
                                            <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                {stock.specs?.drivetrain || ""}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="mb-6 mt-4 ml-2 sm:ml-4 md:ml-28 flex-1">
                                        <div className="dark:bg-gray-700 bg-opacity-60 rounded p-4 h-full">
                                            <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                Number of chairs
                                            </span>
                                            <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                {stock.specs?.seatNo || ""}
                                            </p>
                                            <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                Installation
                                            </span>
                                            <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                {stock.specs?.installation || ""}
                                            </p>
                                            <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                Dredge (mm)
                                            </span>
                                            <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">2981</p>
                                        </div>
                                    </div>

                                    <div className="mb-6 mt-4 ml-2 sm:ml-4 flex-1">
                                        <div className="dark:bg-gray-700 bg-opacity-60 rounded p-4 h-full">
                                            <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                Door
                                            </span>
                                            <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                {stock.specs?.doorNo || ""}
                                            </p>
                                            <span className="text-lg text-gray-600 dark:text-gray-400">
                                                Maximum Power (Hp)
                                            </span>
                                            <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                {stock.specs?.engineHP || ""}
                                            </p>
                                            <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                Torque (Nm)
                                            </span>
                                            <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                {stock.specs?.engineTorque || ""}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="mb-6 mt-4 ml-2 sm:ml-4 flex-1">
                                        <div className="dark:bg-gray-700 bg-opacity-60 rounded p-4 h-full">
                                            <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                Compression Ratio
                                            </span>
                                            <p className="mb-4 text-lg mt-0 text-blue-500 dark:text-blue-200">
                                                {stock.specs?.engineCompressionRatio || ""}
                                            </p>
                                            <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                Stroke (mm)
                                            </span>
                                            <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                {stock.specs?.engineStroke || ""}
                                            </p>
                                            <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                Engine Model
                                            </span>
                                            <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                {stock.specs?.engineModel || ""}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="mb-6 flex flex-wrap items-stretch justify-center sm:justify-start">
                                        <div className="mb-6 mt-4 ml-2 flex-1">
                                            <div className="dark:bg-gray-700 bg-opacity-75 rounded p-4 h-full">
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Type of Engine
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.engineType || ""}
                                                </p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Horsepower
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">443</p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Cylinder
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">{stock.specs?.engineCylinder || ""}</p>
                                            </div>
                                        </div>

                                        <div className="mb-6 mt-4 ml-2 sm:ml-4 md:ml-28 flex-1">
                                            <div className="dark:bg-gray-700 bg-opacity-60 rounded p-4 h-full">
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Length (mm)
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">{stock.specs?.length || ""}</p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Width (mm)
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">{stock.specs?.width || ""}</p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Height (mm)
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">{stock.specs?.height || ""}</p>
                                            </div>
                                        </div>

                                        <div className="mb-6 mt-4 ml-2 sm:ml-4 flex-1">
                                            <div className="dark:bg-gray-700 bg-opacity-60 rounded p-4 h-full">
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Wheelbase (mm)
                                                </span>
                                                <p className="mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.wheelbase || ""}
                                                </p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Weight (kg)
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">{stock.specs?.weight || ""}</p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Boots (litres)
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">{stock.specs?.boots || ""}</p>
                                            </div>
                                        </div>

                                        <div className="mb-6 mt-4 ml-2 sm:ml-4 flex-1">
                                            <div className="dark:bg-gray-700 bg-opacity-60 rounded p-4 h-full">
                                                <span className="text-xl mt-4 justify-center text-gray-600 dark:text-gray-400">
                                                    Front Brake
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.frontBrake || ""}
                                                </p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Rear Brake
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.rearBrake || ""}
                                                </p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Front Tyre
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.frontTireSize || ""}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-6 flex flex-wrap items-stretch justify-center sm:justify-start">
                                        <div className="mb-6 mt-4 ml-2 flex-1">
                                            <div className="dark:bg-gray-700 bg-opacity-75 rounded p-4 h-full">
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Front Rim (inches)
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">18</p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Rear Rim (inches)
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">18</p>
                                                <span className="text-xl mt-0 text-gray-600 dark:text-gray-400">
                                                    Rear Tyre
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.rearTireSize || ""}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="mb-6 mt-4 ml-2 sm:ml-4 md:ml-28 flex-1">
                                            <div className="dark:bg-gray-700 bg-opacity-60 rounded p-4 h-full">
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    C0-100 km/j
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">{stock.specs?.speed0To100 || ""}</p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Top Speed (km/h)
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.topSpeed || ""}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="mb-6 mt-4 ml-2 sm:ml-4 flex-1">
                                            <div className="dark:bg-gray-700 bg-opacity-60 rounded p-4 h-full">
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Front Suspension
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.frontSuspension || ""}
                                                </p>
                                                <span className="text-xl mt-4 text-gray-600 dark:text-gray-400">
                                                    Fuel Tank (liter)
                                                </span>
                                                <p className="mt-0 mb-4 text-lg text-blue-500 dark:text-blue-200">
                                                    {stock.specs?.fuelTank || ""}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}



                            <div className="flex justify-center p-4 border-t">
                                {activeTab > 0 && (
                                    <button
                                        className="py-2 px-4 border rounded-md text-blue-600 border-blue-600 uppercase text-sm font-bold hover:bg-blue-500 hover:text-white transition"
                                        onClick={() => setActiveTab(activeTab - 1)}
                                    >
                                        See Less
                                    </button>
                                )}

                                {activeTab < 1 && (
                                    <button
                                        className="py-2 px-4 border rounded-md text-blue-600 border-blue-600 uppercase text-sm font-bold hover:bg-blue-500 hover:text-white transition ml-4"
                                        onClick={() => setActiveTab(activeTab + 1)}
                                    >
                                        See All
                                    </button>
                                )}
                            </div>
                        </div>

                    </div>


                </section>

                {stock.salesProgressStatus !== "In Progress" ? (
                    <section className="bg-gray-50 py-12 sm:py-16 lg:py-20 xl:py-24">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="text-center">
                                <p className="text-sm font-bold uppercase tracking-widest text-gray-700">
                                    How It Works
                                </p>
                                <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                                    Car Buying Process
                                </h2>
                                <p className="mx-auto mt-4 max-w-2xl text-lg font-normal text-gray-700 lg:text-xl lg:leading-8">
                                    Buy your own car with us with just 4 easy steps
                                </p>
                            </div>
                            <ul className="mx-auto mt-12 grid max-w-md grid-cols-1 gap-10 sm:mt-16 lg:mt-20 lg:max-w-5xl lg:grid-cols-4">
                                <li className="flex-start group relative flex lg:flex-col">
                                    <span
                                        className="absolute left-[18px] top-14 h-[calc(100%_-_32px)] w-px bg-gray-400 lg:right-0 lg:left-auto lg:top-[18px] lg:h-px lg:w-[calc(100%_-_72px)]"
                                        aria-hidden="true"
                                    />
                                    <div
                                        className="inline-flex h-10 w-10 shrink-0 items-center justify-center rounded-xl border border-gray-300 bg-gray-50 transition-all duration-200 group-hover:border-gray-900 group-hover:bg-gray-900">
                                        <svg
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 text-gray-600 group-hover:text-white"
                                        >
                                            <path
                                                d="M21 12C21 13.6569 16.9706 15 12 15C7.02944 15 3 13.6569 3 12M21 5C21 6.65685 16.9706 8 12 8C7.02944 8 3 6.65685 3 5M21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5M21 5V19C21 20.6569 16.9706 22 12 22C7.02944 22 3 20.6569 3 19V5"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div className="ml-6 lg:ml-0 lg:mt-10">
                                        <h3 className="text-xl font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                                            Book Online
                                        </h3>
                                        <h4 className="mt-2 text-base text-gray-700">
                                            Book a car online or schedule a phone call with us.
                                        </h4>
                                    </div>
                                </li>
                                <li className="flex-start group relative flex lg:flex-col">
                                    <span
                                        className="absolute left-[18px] top-14 h-[calc(100%_-_32px)] w-px bg-gray-400 lg:right-0 lg:left-auto lg:top-[18px] lg:h-px lg:w-[calc(100%_-_72px)]"
                                        aria-hidden="true"
                                    />
                                    <div
                                        className="inline-flex h-10 w-10 shrink-0 items-center justify-center rounded-xl border border-gray-300 bg-gray-50 transition-all duration-200 group-hover:border-gray-900 group-hover:bg-gray-900">
                                        <svg
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 text-gray-600 group-hover:text-white"
                                        >
                                            <path
                                                d="M2 3L2 21M22 3V21M11.8 20H12.2C13.8802 20 14.7202 20 15.362 19.673C15.9265 19.3854 16.3854 18.9265 16.673 18.362C17 17.7202 17 16.8802 17 15.2V8.8C17 7.11984 17 6.27976 16.673 5.63803C16.3854 5.07354 15.9265 4.6146 15.362 4.32698C14.7202 4 13.8802 4 12.2 4H11.8C10.1198 4 9.27976 4 8.63803 4.32698C8.07354 4.6146 7.6146 5.07354 7.32698 5.63803C7 6.27976 7 7.11984 7 8.8V15.2C7 16.8802 7 17.7202 7.32698 18.362C7.6146 18.9265 8.07354 19.3854 8.63803 19.673C9.27976 20 10.1198 20 11.8 20Z"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div className="ml-6 lg:ml-0 lg:mt-10">
                                        <h3 className="text-xl font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                                            Test drive
                                        </h3>
                                        <h4 className="mt-2 text-base text-gray-700">
                                            Visit our Experience Center for a test drive.
                                        </h4>
                                    </div>
                                </li>
                                <li className="flex-start group relative flex lg:flex-col">
                                    <span
                                        className="absolute left-[18px] top-14 h-[calc(100%_-_32px)] w-px bg-gray-400 lg:right-0 lg:left-auto lg:top-[18px] lg:h-px lg:w-[calc(100%_-_72px)]"
                                        aria-hidden="true"
                                    />
                                    <div
                                        className="inline-flex h-10 w-10 shrink-0 items-center justify-center rounded-xl border border-gray-300 bg-gray-50 transition-all duration-200 group-hover:border-gray-900 group-hover:bg-gray-900">
                                        <svg
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 text-gray-600 group-hover:text-white"
                                        >
                                            <path
                                                d="M22 12C22 17.5228 17.5228 22 12 22M22 12C22 6.47715 17.5228 2 12 2M22 12C22 9.79086 17.5228 8 12 8C6.47715 8 2 9.79086 2 12M22 12C22 14.2091 17.5228 16 12 16C6.47715 16 2 14.2091 2 12M12 22C6.47715 22 2 17.5228 2 12M12 22C14.2091 22 16 17.5228 16 12C16 6.47715 14.2091 2 12 2M12 22C9.79086 22 8 17.5228 8 12C8 6.47715 9.79086 2 12 2M2 12C2 6.47715 6.47715 2 12 2"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div className="ml-6 lg:ml-0 lg:mt-10">
                                        <h3 className="text-xl font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                                            Make payment
                                        </h3>
                                        <h4 className="mt-2 text-base text-gray-700">
                                            Pay in cash or loan. We will manage all processing for free..
                                        </h4>
                                    </div>
                                </li>
                                <li className="flex-start group relative flex lg:flex-col">
                                    <div
                                        className="inline-flex h-10 w-10 shrink-0 items-center justify-center rounded-xl border border-gray-400 bg-gray-50 transition-all duration-200 group-hover:border-gray-900 group-hover:bg-gray-900">
                                        <svg
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 text-gray-600 group-hover:text-white"
                                        >
                                            <path
                                                d="M5.50049 10.5L2.00049 7.9999L3.07849 6.92193C3.964 6.03644 4.40676 5.5937 4.9307 5.31387C5.39454 5.06614 5.90267 4.91229 6.42603 4.86114C7.01719 4.80336 7.63117 4.92617 8.85913 5.17177L10.5 5.49997M18.4999 13.5L18.8284 15.1408C19.0742 16.3689 19.1971 16.983 19.1394 17.5743C19.0883 18.0977 18.9344 18.6059 18.6867 19.0699C18.4068 19.5939 17.964 20.0367 17.0783 20.9224L16.0007 22L13.5007 18.5M7 16.9998L8.99985 15M17.0024 8.99951C17.0024 10.1041 16.107 10.9995 15.0024 10.9995C13.8979 10.9995 13.0024 10.1041 13.0024 8.99951C13.0024 7.89494 13.8979 6.99951 15.0024 6.99951C16.107 6.99951 17.0024 7.89494 17.0024 8.99951ZM17.1991 2H16.6503C15.6718 2 15.1826 2 14.7223 2.11053C14.3141 2.20853 13.9239 2.37016 13.566 2.5895C13.1623 2.83689 12.8164 3.18282 12.1246 3.87469L6.99969 9C5.90927 10.0905 5.36406 10.6358 5.07261 11.2239C4.5181 12.343 4.51812 13.6569 5.07268 14.776C5.36415 15.3642 5.90938 15.9094 6.99984 16.9998V16.9998C8.09038 18.0904 8.63565 18.6357 9.22386 18.9271C10.343 19.4817 11.6569 19.4817 12.7761 18.9271C13.3643 18.6356 13.9095 18.0903 15 16.9997L20.1248 11.8745C20.8165 11.1827 21.1624 10.8368 21.4098 10.4331C21.6291 10.0753 21.7907 9.6851 21.8886 9.27697C21.9991 8.81664 21.9991 8.32749 21.9991 7.34918V6.8C21.9991 5.11984 21.9991 4.27976 21.6722 3.63803C21.3845 3.07354 20.9256 2.6146 20.3611 2.32698C19.7194 2 18.8793 2 17.1991 2Z"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div className="ml-6 lg:ml-0 lg:mt-10">
                                        <h3 className="text-xl font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                                            Delivery or Pick Up
                                        </h3>
                                        <h4 className="mt-2 text-base text-gray-700">
                                            Pick up your car at the Experience Center or we can deliver the car
                                            to your home.
                                        </h4>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>
                ) : (
                    <></>
                )}

            </main>
            <Footer />
        </>
    );
}

export default DetailsPage;
