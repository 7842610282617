import Header from "../component/header";
import Newsletter from "../component/newsletter";
import DownloadSection from "../component/download";
import Footer from "../component/footer";

const SpecificationPage = () => {
    return (
        <>
            <Header/>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
                {/* Navbar */}
                <div className="w-full px-6 py-6 mx-auto">
                    <h3 className="text-black font-bold">Car's Gallery</h3>
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main=""
                        navbar-scroll="false"
                    >
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <a
                                                href="../../../src/build/dashboard.html"
                                                className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Dashboard</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <a
                                                href="../../../src/build/stock/stock-detail.html"
                                                className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Stock</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <a className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700">
                                                <span className="">Car's Gallery</span>
                                            </a>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    {/* end Navbar */}
                    <div className="w-full p-6 mx-auto">
                        <div className="flex flex-wrap -mx-3">
                            <div className="flex-none w-full max-w-full px-3 mt-6">
                                <div
                                    className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                                    <div className="max-full mx-auto">
                                        <div className="border-b border-gray-200 dark:border-gray-700 mb-4">
                                            <ul
                                                className="flex flex-wrap -mb-px"
                                                id="myTab"
                                                data-tabs-toggle="#myTabContent"
                                                role="tablist"
                                            >
                                                <li className="mr-2" role="presentation">
                                                    <button
                                                        className="inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-4 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300"
                                                        id="profile-tab"
                                                        data-tabs-target="#profile"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="profile"
                                                        aria-selected="false"
                                                    >
                                                        Car's Gallery
                                                    </button>
                                                </li>
                                                <li className="mr-2" role="presentation">
                                                    <button
                                                        className="inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-4 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300 active"
                                                        id="dashboard-tab"
                                                        data-tabs-target="#dashboard"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="dashboard"
                                                        aria-selected="true"
                                                    >
                                                        Car's Details
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div id="myTabContent">
                                            <div
                                                className="bg-white p-4 rounded-lg dark:bg-gray-800 hidden"
                                                id="profile"
                                                role="tabpanel"
                                                aria-labelledby="profile-tab"
                                            >
                                                <div className="p-4 pb-0 mb-0 bg-white rounded-t-2xl">
                                                    <h6 className="mb-1">Car's Gallery</h6>
                                                    <p className="leading-normal text-sm">12/12</p>
                                                </div>
                                                <div className="flex-auto p-4">
                                                    <div className="flex flex-wrap -mx-3">
                                                        <div
                                                            className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                                                <div className="relative">
                                                                    <a className="block shadow-xl rounded-2xl">
                                                                        <img
                                                                            src="https://cdn.carbuzz.com/gallery-images/2023-mercedes-benz-glc-class-coupe-front-angle-view-carbuzz-600274-1600.jpg"
                                                                            alt="img-blur-shadow"
                                                                            className="max-w-full max-h-full shadow-soft-2xl rounded-2xl"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="flex-auto px-1 pt-6">
                                                                    <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                                                                        Overall shoot
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                                                <div className="relative">
                                                                    <a className="block shadow-xl rounded-2xl">
                                                                        <img
                                                                            src="https://paultan.org/image/2023/11/Mercedes-Benz-GLC300-4Matic-Coupe-Malaysia-5-1260x839.jpg"
                                                                            alt="img-blur-shadow"
                                                                            className="max-w-full shadow-soft-2xl rounded-xl"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="flex-auto px-1 pt-6">
                                                                    <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                                                                        Left side
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                                                <div className="relative">
                                                                    <a className="block shadow-xl rounded-2xl">
                                                                        <img
                                                                            src="https://paultan.org/image/2023/11/Mercedes-Benz-GLC300-4Matic-Coupe-Malaysia-12-1260x839.jpg"
                                                                            alt="img-blur-shadow"
                                                                            className="max-w-full shadow-soft-2xl rounded-2xl"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="flex-auto px-1 pt-6">
                                                                    <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                                                                        Right side
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                                                <div className="relative">
                                                                    <a className="block shadow-xl rounded-2xl">
                                                                        <img
                                                                            src="https://paultan.org/image/2023/11/Mercedes-Benz-GLC300-4Matic-Coupe-Malaysia-8-1260x839.jpg"
                                                                            alt="img-blur-shadow"
                                                                            className="max-w-full shadow-soft-2xl rounded-2xl"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="flex-auto px-1 pt-6">
                                                                    <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                                                                        Front
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                                                <div className="relative">
                                                                    <a className="block shadow-xl rounded-2xl">
                                                                        <img
                                                                            src="https://paultan.org/image/2023/11/Mercedes-Benz-GLC300-4Matic-Coupe-Malaysia-19-1260x839.jpg"
                                                                            alt="img-blur-shadow"
                                                                            className="max-w-full shadow-soft-2xl rounded-2xl"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="flex-auto px-1 pt-6">
                                                                    <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                                                                        Back
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                                                <div className="relative">
                                                                    <a className="block shadow-xl rounded-2xl">
                                                                        <img
                                                                            src="https://paultan.org/image/2023/11/Mercedes-Benz-GLC300-4Matic-Coupe-Malaysia-18-1260x813.jpg"
                                                                            alt="img-blur-shadow"
                                                                            className="max-w-full shadow-soft-2xl rounded-2xl"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="flex-auto px-1 pt-6">
                                                                    <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                                                                        Top-view
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                                                <div className="relative">
                                                                    <a className="block shadow-xl rounded-2xl">
                                                                        <img
                                                                            src="https://paultan.org/image/2023/11/Mercedes-Benz-GLC300-4Matic-Coupe-Malaysia-30-1260x839.jpg"
                                                                            alt="img-blur-shadow"
                                                                            className="max-w-full shadow-soft-2xl rounded-2xl"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="flex-auto px-1 pt-6">
                                                                    <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                                                                        Dashboard
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                                                <div className="relative">
                                                                    <a className="block shadow-xl rounded-2xl">
                                                                        <img
                                                                            src="https://paultan.org/image/2023/11/Mercedes-Benz-GLC300-4Matic-Coupe-Malaysia-31-1260x839.jpg"
                                                                            alt="img-blur-shadow"
                                                                            className="max-w-full shadow-soft-2xl rounded-2xl"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="flex-auto px-1 pt-6">
                                                                    <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                                                                        Overview
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                                                <div className="relative">
                                                                    <a className="block shadow-xl rounded-2xl">
                                                                        <img
                                                                            src="https://paultan.org/image/2023/11/Mercedes-Benz-GLC300-4Matic-Coupe-Malaysia-27-1260x839.jpg"
                                                                            alt="img-blur-shadow"
                                                                            className="max-w-full shadow-soft-2xl rounded-2xl"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="flex-auto px-1 pt-6">
                                                                    <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                                                                        Frontseat
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                                                <div className="relative">
                                                                    <a className="block shadow-xl rounded-2xl">
                                                                        <img
                                                                            src="https://paultan.org/image/2023/11/Mercedes-Benz-GLC300-4Matic-Coupe-Malaysia-35-1260x839.jpg"
                                                                            alt="img-blur-shadow"
                                                                            className="max-w-full shadow-soft-2xl rounded-2xl"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="flex-auto px-1 pt-6">
                                                                    <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                                                                        Backseat
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                                                <div className="relative">
                                                                    <a className="block shadow-xl rounded-2xl">
                                                                        <img
                                                                            src="https://paultan.org/image/2023/11/Mercedes-Benz-GLC300-4Matic-Coupe-Malaysia-37-1260x839.jpg"
                                                                            alt="img-blur-shadow"
                                                                            className="max-w-full shadow-soft-2xl rounded-2xl"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="flex-auto px-1 pt-6">
                                                                    <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                                                                        Engine
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                                                <div className="relative">
                                                                    <a className="block shadow-xl rounded-2xl">
                                                                        <img
                                                                            src="https://paultan.org/image/2023/11/Mercedes-Benz-GLC300-4Matic-Coupe-Malaysia-14-1260x912.jpg"
                                                                            alt="img-blur-shadow"
                                                                            className="max-w-full shadow-soft-2xl rounded-2xl"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="flex-auto px-1 pt-6">
                                                                    <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                                                                        Boot
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {" "}
                                            </div>
                                            <div
                                                className="bg-white p-4 rounded-lg dark:bg-gray-800"
                                                id="dashboard"
                                                role="tabpanel"
                                                aria-labelledby="dashboard-tab"
                                            >
                                                <div className="flex flex-wrap -mx-3">
                                                    <div className="flex-none w-full max-w-full px-3">
                                                        <div className="w-full max-w-full px-3 shrink-0 md:flex-0">
                                                            <div className="flex justify-center items-center">
                                                                <ul
                                                                    className="flex flex-wrap p-1 list-none bg-gray-50 rounded-xl"
                                                                    nav-pills=""
                                                                    role="tablist"
                                                                >
                                                                    <li className="z-30 flex-auto text-center">
                                                                        <a
                                                                            className="z-30 flex items-center justify-center w-full px-0 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                                                            nav-link=""
                                                                            active=""
                                                                            role="tab"
                                                                            aria-selected="true"
                                                                        >
                                  <span className="font-semibold ml-2">
                                    1. Car Overview
                                  </span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="z-30 flex-auto text-center">
                                                                        <div className="flex flex-row w-full">
                                                                            <a
                                                                                className="z-30 flex items-center justify-center w-full px-0 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                                                                nav-link=""
                                                                                href="../../../src/build/stock/car-specification.html"
                                                                                role="tab"
                                                                                aria-selected="false"
                                                                            >
                                    <span className="ml-6">
                                      2. Car Specification
                                    </span>
                                                                            </a>
                                                                            <div
                                                                                className="z-50 hidden px-2 py-1 text-center text-white bg-black rounded-lg max-w-46 text-sm"
                                                                                id="tooltip"
                                                                                role="tooltip"
                                                                                data-target="tooltip"
                                                                            >
                                                                                My tooltip
                                                                                <div
                                                                                    id="arrow"
                                                                                    className="invisible absolute h-2 w-2 bg-inherit before:visible before:absolute before:h-2 before:w-2 before:rotate-45 before:bg-inherit before:content-['']"
                                                                                    data-popper-arrow=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-auto p-6">
                                                        <div className="flex flex-wrap -mx-3">
                                                            <div
                                                                className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0">
                                                                <div className="mb-4">
                                                                    <label
                                                                        htmlFor="name"
                                                                        className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                                    >
                                                                        Fuel Type
                                                                    </label>
                                                                    <input
                                                                        placeholder="Petrol"
                                                                        type="text"
                                                                        name="name"
                                                                        className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap -mx-3">
                                                            <div
                                                                className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0">
                                                                <div className="mb-4">
                                                                    <label
                                                                        htmlFor="address"
                                                                        className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                                    >
                                                                        Car Color
                                                                    </label>
                                                                    <input
                                                                        placeholder="Grey"
                                                                        type="text"
                                                                        name="address"
                                                                        className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                                <div className="mb-4">
                                                                    <label
                                                                        htmlFor="parliament"
                                                                        className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                                    >
                                                                        Seating
                                                                    </label>
                                                                    <select
                                                                        className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                        name="parliament"
                                                                    >
                                                                        <option>2</option>
                                                                        <option>4</option>
                                                                        <option>5</option>
                                                                        <option>7</option>
                                                                        <option>8</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                                <div className="mb-4">
                                                                    <label
                                                                        htmlFor="parliament"
                                                                        className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                                    >
                                                                        Current Mileage
                                                                    </label>
                                                                    <input
                                                                        placeholder="37,447 KM"
                                                                        type="text"
                                                                        name="datepicker"
                                                                        className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap -mx-3">
                                                            <div
                                                                className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                                <div className="mb-4">
                                                                    <label
                                                                        htmlFor="datepicker"
                                                                        className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                                    >
                                                                        Date of registration
                                                                    </label>
                                                                    <input
                                                                        placeholder="Jul 2019"
                                                                        type="text"
                                                                        name="datepicker"
                                                                        className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                                <div className="mb-4">
                                                                    <label
                                                                        htmlFor="datepicker"
                                                                        className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                                    >
                                                                        Registration Type
                                                                    </label>
                                                                    <input
                                                                        placeholder="Private"
                                                                        type="text"
                                                                        name="datepicker"
                                                                        className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap -mx-3">
                                                            <div
                                                                className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                                <div className="mb-4">
                                                                    <label
                                                                        htmlFor="datepicker"
                                                                        className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                                    >
                                                                        Spare Key
                                                                    </label>
                                                                    <input
                                                                        placeholder="Available"
                                                                        type="text"
                                                                        name="datepicker"
                                                                        className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                                <div className="mb-4">
                                                                    <label
                                                                        htmlFor="datepicker"
                                                                        className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                                    >
                                                                        Manufacturer's Warranty
                                                                    </label>
                                                                    <input
                                                                        placeholder="Not Available"
                                                                        type="text"
                                                                        name="datepicker"
                                                                        className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap -mx-3">
                                                            <div
                                                                className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0">
                                                                <div className="mb-4">
                                                                    <label
                                                                        htmlFor="telephone"
                                                                        className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                                    >
                                                                        Image
                                                                    </label>
                                                                    <div className="flex flex-row w-full">
                                                                        <form
                                                                            dropzone=""
                                                                            action="/file-upload"
                                                                            className="dropzone focus:shadow-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease block w-full appearance-none rounded-md border border-solid border-gray-300 bg-white bg-clip-border px-3 py-2 mr-4 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                            id="dropzone"
                                                                        >
                                                                            <div className="fallback ml-3 ">
                                                                                <input
                                                                                    name="file"
                                                                                    type="file"
                                                                                    multiple=""
                                                                                />
                                                                            </div>
                                                                        </form>
                                                                        <form
                                                                            dropzone=""
                                                                            action="/file-upload"
                                                                            className="dropzone focus:shadow-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-border px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                            id="dropzone"
                                                                        >
                                                                            <div className="fallback ml-3">
                                                                                <input
                                                                                    name="file"
                                                                                    type="file"
                                                                                    multiple=""
                                                                                />
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row items-end justify-end w-full">
                                                            <button
                                                                type="button"
                                                                className="px-8 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                            >
                                                                <i className="fa fa-pen" aria-hidden="true"/> Edit
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onclick="successSave('alert-id')"
                                                                className="px-8 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-green-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                            >
                                                                <i className="fa fa-check" aria-hidden="true"/>{" "}
                                                                Save
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="px-8 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-orange-400 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                            >
                                                                <i
                                                                    className="fa fa-arrow-right"
                                                                    aria-hidden="true"
                                                                />{" "}
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-gray-500 dark:text-gray-400 mt-6 ml-3 text-sm">
                                        {" "}
                                        <strong className="font-medium text-gray-800 dark:text-white">
                                            Specifications have been sourced from 3rd party and are based
                                            on manufacturer standards. Actual specifications for this
                                            vehicle may differ, please confirm with DTC Motors
                                            consultants.
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </main>
        </>
    );
}

export default SpecificationPage;

