import React, {useEffect, useState} from 'react';
import {API} from "../config/config";

import './tailwind_theme/tailwind.css';
import './custom_css/custom.css';

import axios from "axios";
import {Link, useLocation} from "react-router-dom";

import Header from "../component/header";
import Newsletter from "../component/newsletter";
import DownloadSection from "../component/download";
import Footer from "../component/footer";

const ListingPage = () => {
    const [tooltipVisible, setTooltipVisible] = useState({});

    const showTooltip = (id) => setTooltipVisible({...tooltipVisible, [id]: true});
    const hideTooltip = (id) => setTooltipVisible({...tooltipVisible, [id]: false});

    const [stocks, setStocks] = useState([]);
    const [soldStocks, setSoldStocks] = useState([]);

    const location = useLocation();

    useEffect(() => {
        fetchFilteredStocks();
        fetchFilteredSoldStocks();
    }, [location.search]);

    // const fetchStocks = async () => {
    //     try {
    //         const response = await axios.get(`${API}/directory/stock/unsold/all`);
    //         console.log(response.data.data);
    //         setStocks(response.data.data);
    //     } catch (error) {
    //         console.error('Error fetching stocks:', error);
    //     }
    // };

    const fetchFilteredStocks = async () => {
        try {
            const url = `${API}/directory/stock/unsold/filter${location.search}`;
            const response = await axios.get(url);
            console.log(response.data.data);
            setStocks(response.data.data);
        } catch (error) {
            console.error('Error fetching filtered stocks:', error);
        }
    };

    const fetchFilteredSoldStocks = async () => {
        try {
            const url = `${API}/directory/stock/sold/filter${location.search}`;
            const response = await axios.get(url);
            setSoldStocks(response.data.data);
        } catch (error) {
            console.error('Error fetching filtered sold stocks:', error);
        }
    }

    const displayHeader = () => {
        if (!location.search) {
            return (
                <div className="px-4 w-full md:w-10/12">
                    <h2 className="font-medium mb-1 text-red-500 text-xl">
                        All Cars
                    </h2>
                    <h3 className="capitalize font-bold mb-4 text-4xl text-gray-900">
                        In Our Stock
                    </h3>
                    <div className="bg-red-500 mb-6 pb-1 w-2/12"/>
                </div>
            );
        } else {
            return (
                <div className="px-4 w-full md:w-10/12">
                    <h2 className="font-medium mb-1 text-red-500 text-xl">
                        Cars
                    </h2>
                    <h3 className="capitalize font-bold mb-4 text-4xl text-gray-900">
                        Based On Your Preferences
                    </h3>
                    <div className="bg-red-500 mb-6 pb-1 w-2/12"/>
                </div>
            );
        }
    };

    return (
        <>
            <Header/>
            <section className="bg-gray-50 py-24">
                <div className="container mx-auto px-4">
                    {displayHeader()}
                    {stocks.length > 0 ? (
                        <div className="-mx-3 flex flex-wrap justify-center mb-12">
                            {stocks.map((stock) => (
                                <div key={stock._id} className="p-3 w-full md:w-6/12 lg:w-4/12">
                                    <Link to={`/details/${stock._id}`}>
                                        <div className="bg-white border shadow-md text-gray-500">
                                            <div
                                                className="relative w-full h-72 overflow-hidden">
                                                <img
                                                    src={stock.gallery.overall}
                                                    className="hover:opacity-90 w-full h-full object-cover" // object-cover should be always on to maintain aspect ratio
                                                    alt={stock.carName}
                                                    width={600}
                                                    height={450}
                                                />
                                                {stock.salesProgressStatus === "In Progress" && (
                                                    <div
                                                        className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black opacity-70">
                                                        <span
                                                            className="text-white text-3xl font-bold">Purchase Pending</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="p-6">
                                                <h4 className="font-bold mb-2 text-gray-900 text-xl">
                                                    <a className="hover:text-gray-500">
                                                        {stock.brand} {stock.carName}
                                                    </a>
                                                </h4>
                                                <div className="flex-wrap inline-flex items-center py-1 space-x-2">
                                                    <div
                                                        className="rounded-full border border-primary-500 inline-block px-6 py-1 text-red-500"
                                                    >
                                                        {stock.type}
                                                    </div>
                                                    {stock.discountedValue ? (
                                                        <div
                                                            className="rounded-full bg-red-500 border border-primary-500 inline-block px-6 py-1 text-white"
                                                        >
                                                            Great Deals
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                </div>

                                                <div className="flex items-center">
                                                    {stock.discountedValue ? (
                                                        <>
                                                            <p className="font-bold text-gray-500 line-through">RM {stock.value.toLocaleString()}</p>
                                                            <p className="font-bold text-gray-900">RM {stock.discountedValue.toLocaleString()}</p>
                                                        </>
                                                    ) : (
                                                        <p className="font-bold text-gray-900">RM {stock.value.toLocaleString()}</p>
                                                    )}
                                                    {/*<div className="relative">*/}
                                                    {/*    /!* Tooltip trigger *!/*/}
                                                    {/*    <button*/}
                                                    {/*        onMouseOver={() => showTooltip(stock._id)}*/}
                                                    {/*        onMouseOut={() => hideTooltip(stock._id)}*/}
                                                    {/*        onFocus={() => showTooltip(stock._id)}*/}
                                                    {/*        onBlur={() => hideTooltip(stock._id)}*/}
                                                    {/*        className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"*/}
                                                    {/*        aria-haspopup="true"*/}
                                                    {/*    >*/}
                                                    {/*        <svg*/}
                                                    {/*            className="icon icon-tabler icon-tabler-info-circle h-6 w-6 text-gray-500"*/}
                                                    {/*            viewBox="0 0 24 24"*/}
                                                    {/*            strokeWidth="1.5"*/}
                                                    {/*            stroke="currentColor"*/}
                                                    {/*            fill="none"*/}
                                                    {/*            strokeLinecap="round"*/}
                                                    {/*            strokeLinejoin="round"*/}
                                                    {/*        >*/}
                                                    {/*            <circle cx={12} cy={12} r={9}/>*/}
                                                    {/*            <line x1={12} y1={8} x2={12.01} y2={8}/>*/}
                                                    {/*            <polyline points="11 12 12 12 12 16 13 16"/>*/}
                                                    {/*        </svg>*/}
                                                    {/*    </button>*/}

                                                    {/*    /!* Tooltip body *!/*/}
                                                    {/*     {tooltipVisible[stock._id] && (*/}
                                                    {/*     <div*/}
                                                    {/*     className="absolute z-50 left-0 ml-10 w-64 p-2 bg-gray-800 text-white rounded shadow-lg">*/}
                                                    {/*     <p className="text-sm font-bold">Discount Price</p>*/}
                                                    {/*     <p className="text-xs">RM 6000 Off</p>*/}
                                                    {/*     <p className="text-xs">Estimated monthly payments are*/}
                                                    {/*     based on:</p>*/}
                                                    {/*     <p className="text-xs">10% Down Payment</p>*/}
                                                    {/*     <p className="text-xs">Annual Interest Rate 3.5%</p>*/}
                                                    {/*     <p className="text-xs">9 Year Loan Term</p>*/}
                                                    {/*     </div>*/}
                                                    {/*     )}*/}

                                                    {/*</div>*/}
                                                </div>

                                                <div className="flex items-center justify-between">
                                                    <div className="inline-flex items-center py-1 space-x-1">
                                                        <span>{stock.specs.mileage} KM</span>
                                                        <svg
                                                            className="h-8 w-8 text-red-500"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            {" "}
                                                            <path stroke="none" d="M0 0h24v24H0z"/>
                                                            {" "}
                                                            <line x1={12} y1={18} x2="12.01" y2={18}/>
                                                        </svg>
                                                        <span>{stock.specs.transmissionType}</span>
                                                        <svg
                                                            className="h-8 w-8 text-red-500"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            {" "}
                                                            <path stroke="none" d="M0 0h24v24H0z"/>
                                                            {" "}
                                                            <line x1={12} y1={18} x2="12.01" y2={18}/>
                                                        </svg>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                                            />
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                                                            />
                                                        </svg>
                                                        <span>{stock.stockLocation}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            ))}
                        </div>
                    ) : (
                        <div className="text-center">
                            <h4 className="text-xl font-semibold text-gray-900">No results found.</h4>
                            <p className="text-gray-600">Try adjusting your search or filter to find what you're looking
                                for.</p>
                        </div>
                    )}
                </div>
            </section>

            {soldStocks.length > 0 ? (
                <section className="bg-gray-900 py-24">
                    <div className="container mx-auto px-4">
                        <div className="px-4 w-full md:w-10/12">
                            <h2 className="font-medium mb-1 text-red-500 text-xl">
                                Recent Sold Cars
                            </h2>
                            <h3 className="capitalize font-bold mb-4 text-4xl text-white">
                                Based On Filtered Search
                            </h3>
                            <div className="bg-red-500 mb-6 pb-1 w-2/12"/>
                        </div>
                        <div className="-mx-3 flex flex-wrap justify-center mb-12">
                            {soldStocks.slice(0, 3).map((stock) => (
                                <div key={stock._id} className="p-3 w-full md:w-6/12 lg:w-4/12">
                                    <div className="bg-white border shadow-md text-gray-500">
                                        <img
                                            src={stock.gallery.overall}
                                            className="w-full h-60"
                                            alt={stock.carName}
                                            width={600}
                                            height={450}
                                        />
                                        <div className="p-6">
                                            <h4 className="font-bold mb-2 text-gray-900 text-xl">
                                                <a className="hover:text-gray-500">
                                                    {stock.brand} {stock.carName}
                                                </a>
                                            </h4>
                                            <div className="flex-wrap inline-flex items-center py-1 space-x-2">
                                                <div
                                                    className="rounded-full border border-primary-500 inline-block px-6 py-1 text-red-500"
                                                >
                                                    {stock.type}
                                                </div>
                                                {stock.discountedValue ? (
                                                    <div
                                                        className="rounded-full bg-red-500 border border-primary-500 inline-block px-6 py-1 text-white"
                                                    >
                                                        Great Deals
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}

                                            </div>

                                            <div className="flex items-center">
                                                {stock.discountedValue ? (
                                                    <>
                                                        <p className="font-bold text-gray-500 line-through">RM {stock.value.toLocaleString()}</p>
                                                        <p className="font-bold text-gray-900">RM {stock.discountedValue.toLocaleString()}</p>
                                                    </>
                                                ) : (
                                                    <p className="font-bold text-gray-900">RM {stock.value.toLocaleString()}</p>
                                                )}
                                                {/*<div className="relative">*/}
                                                {/*    /!* Tooltip trigger *!/*/}
                                                {/*    <button*/}
                                                {/*        onMouseOver={() => showTooltip(stock._id)}*/}
                                                {/*        onMouseOut={() => hideTooltip(stock._id)}*/}
                                                {/*        onFocus={() => showTooltip(stock._id)}*/}
                                                {/*        onBlur={() => hideTooltip(stock._id)}*/}
                                                {/*        className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"*/}
                                                {/*        aria-haspopup="true"*/}
                                                {/*    >*/}
                                                {/*        <svg*/}
                                                {/*            className="icon icon-tabler icon-tabler-info-circle h-6 w-6 text-gray-500"*/}
                                                {/*            viewBox="0 0 24 24"*/}
                                                {/*            strokeWidth="1.5"*/}
                                                {/*            stroke="currentColor"*/}
                                                {/*            fill="none"*/}
                                                {/*            strokeLinecap="round"*/}
                                                {/*            strokeLinejoin="round"*/}
                                                {/*        >*/}
                                                {/*            <circle cx={12} cy={12} r={9}/>*/}
                                                {/*            <line x1={12} y1={8} x2={12.01} y2={8}/>*/}
                                                {/*            <polyline points="11 12 12 12 12 16 13 16"/>*/}
                                                {/*        </svg>*/}
                                                {/*    </button>*/}

                                                {/*    /!* Tooltip body *!/*/}
                                                {/*     {tooltipVisible[stock._id] && (*/}
                                                {/*     <div*/}
                                                {/*     className="absolute z-50 left-0 ml-10 w-64 p-2 bg-gray-800 text-white rounded shadow-lg">*/}
                                                {/*     <p className="text-sm font-bold">Discount Price</p>*/}
                                                {/*     <p className="text-xs">RM 6000 Off</p>*/}
                                                {/*     <p className="text-xs">Estimated monthly payments are*/}
                                                {/*     based on:</p>*/}
                                                {/*     <p className="text-xs">10% Down Payment</p>*/}
                                                {/*     <p className="text-xs">Annual Interest Rate 3.5%</p>*/}
                                                {/*     <p className="text-xs">9 Year Loan Term</p>*/}
                                                {/*     </div>*/}
                                                {/*     )}*/}

                                                {/*</div>*/}
                                            </div>

                                            <div className="flex items-center justify-between">
                                                <div className="inline-flex items-center py-1 space-x-1">
                                                    <span>{stock.specs.mileage} KM</span>
                                                    <svg
                                                        className="h-8 w-8 text-red-500"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={2}
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        {" "}
                                                        <path stroke="none" d="M0 0h24v24H0z"/>
                                                        {" "}
                                                        <line x1={12} y1={18} x2="12.01" y2={18}/>
                                                    </svg>
                                                    <span>{stock.specs.transmissionType}</span>
                                                    <svg
                                                        className="h-8 w-8 text-red-500"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={2}
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        {" "}
                                                        <path stroke="none" d="M0 0h24v24H0z"/>
                                                        {" "}
                                                        <line x1={12} y1={18} x2="12.01" y2={18}/>
                                                    </svg>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                                        />
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                                                        />
                                                    </svg>
                                                    <span>{stock.stockLocation}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>

                    </div>
                </section>
            ) : (<></>)}
            {/*<Newsletter/>*/}
            <DownloadSection/>
            <Footer/>
        </>
    );
};

export default ListingPage;
