import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ScrollTop from "./webhook/scroll-top";


import WebsiteIndex from "./build";
import DetailsPage from "./build/details";
import SpecificationPage from "./build/specification";
import ListingPage from "./build/listing";
import LocationPage from "./build/location";
import AboutPage from "./build/about";
import ArticlePage from "./build/article";
import ArticleDetailsPage from "./build/article-details";
import ListingDealsPage from "./build/listing-deals";
import PrivacyPolicyPage from "./build/privacy-policy";
import TermsOfUsePage from "./build/terms";


function App() {

    return (
        <div className="App">
            <Router basename={`/`}>
                {/*<Header />*/}
                <Switch>
                    <ScrollTop>
                        <Route path="/" component={WebsiteIndex} exact/>
                        <Route path={`/list`} component={ListingPage} exact/>
                        <Route path={`/list-deals`} component={ListingDealsPage} exact/>
                        <Route path={`/details/:id`} component={DetailsPage} exact/>
                        <Route path={`/specification`} component={SpecificationPage} exact/>
                        <Route path={`/location`} component={LocationPage} exact/>
                        <Route path={`/about`} component={AboutPage} exact/>
                        <Route path={`/article`} component={ArticlePage} exact/>
                        <Route path={`/article/:id`} component={ArticleDetailsPage} exact/>
                        <Route path={`/privacy-policy`} component={PrivacyPolicyPage} exact/>
                        <Route path={`/terms`} component={TermsOfUsePage} exact/>
                    </ScrollTop>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
