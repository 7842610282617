import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useParams} from "react-router-dom";
import {API} from "../config/config";

import './tailwind_theme/tailwind.css';
import Header from "../component/header";
import Newsletter from "../component/newsletter";
import DownloadSection from "../component/download";
import Footer from "../component/footer";


const ArticleDetailsPage = () => {
    const {id} = useParams();
    const [article, setArticle] = useState();

    const fetchData = async () => {
        try {
            const response = await axios.get(`${API}/directory/announcement/${id}`);

            if (response.data.status === 'success') {
                setArticle(response.data.data);
            } else {
                console.error('Error fetching article details:', response.data.message);
                setArticle(null); // Set to null if the status is not success
            }
        } catch (error) {
            console.error('Error fetching article details:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);


    if (!article) {
        return (
            <div>Loading...</div>
        );
    }


    return (
        <>
            <Header/>
            <section className="py-6 sm:py-12 dark:bg-gray-800 dark:text-gray-100">
                <div className="container mx-auto bg-gray-900">
                    <div className="bg-gray-900 p-6">
                        <img
                            src={article.picture}
                            alt={article.title}
                            className="w-full h-full object-cover"
                        />
                        <div className="p-4">
                            <h1 className="text-3xl font-bold text-white">{article.title}</h1>
                            <p className="text-white mt-2"># {article.tag}</p>
                            <p className="text-white mt-2">{new Date(article.createdAt).toLocaleDateString()}</p>
                            <p className="text-gray-400 mt-2">Views: {article.hits.length}</p>
                            <p className="text-white mt-4">{article.content}</p>

                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default ArticleDetailsPage;
