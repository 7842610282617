import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {API} from "../config/config";
import {Link} from "react-router-dom";

import './tailwind_theme/tailwind.css';
import Header from "../component/header";
import Newsletter from "../component/newsletter";
import DownloadSection from "../component/download";
import Footer from "../component/footer";


const ArticlePage = () => {

    const [popularAnnouncement, setPopularAnnouncement] = useState([]);
    const [announcement, setAnnouncement] = useState([]);

    const fetchPopularData = async () => {
        try {
            const response = await axios.get(`${API}/directory/announcement`);

            const sortByPopularAnnouncements = response.data.data.sort((a, b) => b.hits.length - a.hits.length).slice(0, 4);

            const sortByUpdatedAnnouncements = response.data.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)).slice(0, 12);

            setPopularAnnouncement(sortByPopularAnnouncements);
            setAnnouncement(sortByUpdatedAnnouncements);
        } catch (error) {
            console.error('Error fetching announcements:', error);
        }
    };

    useEffect(() => {
        fetchPopularData();
    }, []);

    return (
        <>
            <Header/>

            <section className="py-6 sm:py-12 dark:bg-gray-800 dark:text-gray-100">
                <div className="container p-6 mx-auto space-y-8">
                    <div className="space-y-2 text-center">
                        <h2 className="text-3xl font-bold dark:text-gray-400 mb-6">Most Popular</h2>
                    </div>
                    <div className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-4">
                        {popularAnnouncement.map((announcement) => (
                            <Link to={`/article/${announcement._id}`}>
                                <article className="flex flex-col dark:bg-gray-900">
                                    <img
                                        alt={announcement.title}
                                        className="object-cover w-full h-52 dark:bg-gray-500"
                                        src={announcement.picture}
                                    />
                                    <div className="flex flex-col flex-1 p-6">
                                        <h3
                                            className="text-xs tracki uppercase dark:text-gray-400"
                                        >
                                            #{announcement.tag}
                                        </h3>
                                        <h3 className="flex-1 py-2 text-lg dark:text-gray-400 font-semibold leading-4">
                                            {announcement.title}
                                        </h3>
                                        <div
                                            className="flex flex-wrap justify-between pt-3 space-x-2 text-xs dark:text-gray-400">
                                            <span>{new Date(announcement.updatedAt).toLocaleDateString()}</span>
                                            <span>{announcement.hits.length} Views</span>
                                        </div>
                                    </div>
                                </article>
                            </Link>
                        ))}
                    </div>
                </div>
            </section>

            <section className="py-6 sm:py-12 dark:bg-gray-800 dark:text-gray-100">
                <div className="container p-2 mx-auto space-y-8">
                    <div className="space-y-2 text-center">
                        <h2 className="text-3xl font-bold dark:text-gray-400 mb-6">Latest Article</h2>
                    </div>
                    <div className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-4">
                        {announcement.map((announcement) => (
                            <Link to={`/article/${announcement._id}`}>
                                <article className="flex flex-col dark:bg-gray-900">
                                    <img
                                        alt={announcement.title}
                                        className="object-cover w-full h-52 dark:bg-gray-500"
                                        src={announcement.picture}
                                    />
                                    <div className="flex flex-col flex-1 p-6">
                                        <h3
                                            className="text-xs tracki uppercase dark:text-gray-400"
                                        >
                                            #{announcement.tag}
                                        </h3>
                                        <h3 className="flex-1 py-2 text-lg dark:text-gray-400 font-semibold leading-4">
                                            {announcement.title}
                                        </h3>
                                        <div
                                            className="flex flex-wrap justify-between pt-3 space-x-2 text-xs dark:text-gray-400">
                                            <span>{new Date(announcement.updatedAt).toLocaleDateString()}</span>
                                            <span>{announcement.hits.length} Views</span>
                                        </div>
                                    </div>
                                </article>
                            </Link>
                        ))}
                    </div>
                </div>
            </section>
            <Footer/>
        </>

    );
};

export default ArticlePage;
