import './tailwind_theme/tailwind.css';
import Header from "../component/header";
import Footer from "../component/footer";

const PrivacyPolicyPage = () => {
    return (
        <>
            <Header/>

            {/* Privacy Policy Section */}
            <section className="bg-white py-12">
                <div className="container mx-auto">
                    <h2 className="text-4xl font-bold text-gray-900 mb-8">Privacy Policy</h2>
                    <div className="text-lg text-gray-700 dark:text-black">
                        <p className="mb-6">
                            This Personal Data Protection Notice ("Notice") is issued pursuant to the Personal Data
                            Protection Act 2010 ("Act") and for the purpose of this Notice, "Personal Data" shall have
                            the meaning as ascribed to it under the Act.
                        </p>
                        <p className="mb-6">
                            This Notice applies to individuals governed under the Act and serves to inform you how your
                            Personal Data is controlled, processed, and/or used by GenSE Sdn Bhd, the company that
                            manages the DTC Car Dealership website.
                        </p>
                        <h3 className="text-2xl font-semibold mb-4">Collection of Personal Data</h3>
                        <p className="mb-6">
                            The Personal Data controlled, processed, and/or used by GenSE Sdn Bhd includes but is not
                            limited to your name, age, date of birth, identity card number, contact details, occupation,
                            preferences, interests, and information about your firm. We may also collect sensitive
                            Personal Data such as physical or mental health conditions, religious beliefs, or
                            information about any offences committed or alleged.
                        </p>
                        <p className="mb-6">
                            Personal Data is collected directly from the information and/or documents you provide to us,
                            from third parties, and from public domains or online platforms. We may also collect
                            Personal Data during events, training programs, conferences, seminars, workshops, or from
                            cookies used on the DTC Car Dealership website.
                        </p>
                        <h3 className="text-2xl font-semibold mb-4">Purposes of Processing</h3>
                        <p className="mb-6">
                            Your Personal Data may be controlled, processed, and/or used by us for the following
                            purposes:
                        </p>
                        <ul className="list-disc ml-6 mb-6">
                            <li>To facilitate communication and dissemination of information to you.</li>
                            <li>To perform functions related to car bookings, including:
                                <ul className="list-disc ml-6">
                                    <li>Collecting booking details and preferences.</li>
                                    <li>Keeping a log of interactions and bookings for reference.</li>
                                    <li>Maintaining records for customer service and support.</li>
                                </ul>
                            </li>
                            <li>For any other purposes incidental or ancillary to the above.</li>
                        </ul>

                        <h3 className="text-2xl font-semibold mb-4">Consent and Opt-Out</h3>
                        <p className="mb-6">
                            If you do not wish to provide your consent for the processing of your Personal Data, or wish
                            to opt out, you may notify GenSE Sdn Bhd using the contact details provided below.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">Withdrawal or Limitation of Consent</h3>
                        <p className="mb-6">
                            Providing your Personal Data is voluntary. You may withdraw your consent for us to collect,
                            process, use, store, or limit the processing of your Personal Data at any time by notifying
                            us in writing using the address provided below.
                        </p>
                        <p className="mb-6">
                            If you do not provide sufficient Personal Data, we may not be able to communicate with you
                            effectively, which may affect our ability to fulfill the purposes mentioned above and your
                            ability to enjoy our full range of services.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">Disclosure and Transfer</h3>
                        <p className="mb-6">
                            We may disclose your Personal Data to our employees, affiliates, strategic partners, service
                            providers/vendors, agents, consultants, legal advisors, external auditors, and any person
                            under a duty of confidentiality to GenSE Sdn Bhd.
                        </p>
                        <p className="mb-6">
                            We may also disclose your Personal Data to third parties, including professional bodies,
                            training institutions, banks, financial institutions, and governmental/non-governmental
                            authorities, as necessary.
                        </p>
                        <p className="mb-6">
                            Your Personal Data may be transferred to any of the aforementioned parties located within or
                            outside Malaysia for the purposes mentioned above.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">Retention</h3>
                        <p className="mb-6">
                            Personal Data supplied by you will be retained by GenSE Sdn Bhd as long as necessary for the
                            fulfillment of the purposes stated above, in accordance with our internal practices,
                            regulatory requirements, and legal obligations.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">Integrity and Security</h3>
                        <p className="mb-6">
                            We are committed to ensuring that your Personal Data is securely stored in our databases and
                            offices. All reasonable efforts and practical steps are taken to protect your Personal Data
                            from loss, misuse, unauthorized access, modification, or destruction.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">Access and Correction</h3>
                        <p className="mb-6">
                            If you wish to request access to or rectify your Personal Data, or withdraw/limit your
                            consent, you may send your request in writing to the following address:
                        </p>
                        <p className="mb-6">
                            GenSE Sdn. Bhd.<br/>
                            B2-2, Block B, Jalan Radius 1/1B, Off Jalan Teknokrat 2, Radius Business Park,<br/>
                            Cyber 4, 63000 Cyberjaya, Selangor.<br/>
                            Attn.: Head of Information Technology Department<br/>
                            Email: hello@gense.my
                        </p>
                        <p className="mb-6">
                            Your request will be subject to applicable legal restrictions, conditions, and a reasonable
                            time period.
                        </p>

                        <h3 className="text-2xl font-semibold mb-4">Consent</h3>
                        <p className="mb-6">
                            By providing us with your Personal Data or continuing to communicate with DTC Car
                            Dealership, you consent to the processing of such data. GenSE Sdn Bhd reserves the right to
                            modify, update, or amend the terms of this Notice at any time by placing the updated Notice
                            on the DTC Car Dealership website. Continuing to use our services after such changes
                            signifies your acceptance of the updated Notice <h3
                            className="text-2xl font-semibold mb-4">Language</h3>
                            <p className="mb-6">
                                This Notice is provided in both English and Bahasa Malaysia. In case of any
                                inconsistencies between the two, the English version shall prevail.
                            </p>
                        </p>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    );
};

export default PrivacyPolicyPage;
