import React from "react";
import {Link} from "react-router-dom";

const Header = () => {
    return (
        <>
            <title>DTC Motors</title>
            <header className="bg-black bg-opacity-95 py-2">
                <div className="container mx-auto relative">
                    <nav id="header" className="w-full z-30 top-0 py-1">
                        <div
                            className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-6 py-1">

                            <Link to={`/`}
                                  className="font-bold font-sans hover:text-opacity-75 inline-flex items-center leading-none mr-4 space-x-1 text-primary-500 text-xl uppercase"
                            >
                                 <img src="https://lh3.googleusercontent.com/p/AF1QipMxXYqEuFhTZKZNxLc5_bFB97nDCEJFYzel1Wkp=w1080-h608-p-no-v0" alt="Car Image" className="w-19 h-19 rounded" />

                                {/* <span>DTC Motors</span> */}
                                {" "}
                            </Link>
                            <label
                                htmlFor="menu-toggle"
                                className="cursor-pointer md:hidden block"
                            >
                                <svg
                                    className="fill-current text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={20}
                                    height={20}
                                    viewBox="0 0 20 20"
                                >
                                    <title>menu</title>
                                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                                </svg>
                            </label>
                            <input className="hidden" type="checkbox" id="menu-toggle"/>
                            <div
                                className="hidden md:flex md:items-center md:w-auto w-full order-3 md:order-1"
                                id="menu"
                            >
                                <nav>
                                    <ul className="md:flex items-start justify-between text-base text-gray-700 pt-4 pb-2 md:pt-0">
                                        <li>
                                            <Link to={`/`}
                                                  className="hover:text-gray-400 lg:p-4 py-2 text-white"
                                            >
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/list`}
                                                  className="hover:text-gray-400 lg:p-4 py-2 text-white"
                                            >
                                                All Cars
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/list-deals`}
                                                  className="hover:text-gray-400 lg:p-4 py-2 text-white"
                                            >
                                                Great Deals
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/location`}
                                                  className="hover:text-gray-400 lg:p-4 py-2 text-white"
                                            >
                                                Location
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/about`}
                                                  className="hover:text-gray-400 lg:p-4 py-2 text-white"
                                            >
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/article`}
                                                  className="hover:text-gray-400 lg:p-4 py-2 text-white"
                                            >
                                                Article
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
}

export default Header;
