import './tailwind_theme/tailwind.css';
import Header from "../component/header";
import Footer from "../component/footer";

const AboutPage = () => {
    return (
        <>
            <Header />



            {/* Hero Section */}
            <section className="bg-white dark:bg-gray-900">
  <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
    <div className="mr-auto place-self-center lg:col-span-7">
      <h1 className="max-w-2xl mb-4 pt-20 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
        Welcome to 
        <br />
        D.T.C Motors Sdn Bhd
      </h1>
      <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
        About Us
      </p>
      
      <ul
        role="list"
        className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
      >
        <li className="flex space-x-3">
          {/* Icon */}
          <svg
            className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
          <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
            Founded in 1984, D.T.C Motors Sdn Bhd has been a pioneer in the luxury car market in Malaysia. As a proud member of PEKEMA, we have consistently delivered top-notch imported luxury reconditioned cars to our esteemed clientele for over three decades.
          </span>
        </li>
      </ul>
    </div>
    <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
      <img src="https://lh3.googleusercontent.com/p/AF1QipNJBs3NzJtwsODq0fleor7jlLaUw1pm1UFOIFFR=s1360-w1360-h1020" alt="hero image" className="rounded-lg shadow-lg" />
    </div>
  </div>
</section>

            {/* Our Services Section */}
            <section className="bg-white py-12">
                <div className="container mx-auto flex flex-col lg:flex-row items-center">
                    <div className="lg:w-1/2 lg:pr-8 mb-8 lg:mb-0">
                        <img src="https://lh3.googleusercontent.com/p/AF1QipP5JqJ1zmEBdGPti2HhXLuSg-dwMgq28vAhrBOc=s1360-w1360-h1020" alt="Luxury Car" className="w-full h-auto object-cover" />
                    </div>
                    <div className="lg:w-1/2 text-right">
                        <h2 className="text-4xl font-bold text-gray-900 mb-8">Our Services</h2>
                        <p className="text-lg text-gray-900 dark:text-gray-900 mb-6">
                            At D.T.C Motors, we specialize in offering a wide range of imported luxury reconditioned cars. Our meticulous attention to detail and commitment to quality ensure that each vehicle in our collection meets the highest standards of excellence and performance. Whether you are looking for a sleek sports car or a sophisticated executive vehicle, our selection caters to the discerning tastes of car enthusiasts and luxury connoisseurs alike.
                        </p>
                    </div>
                </div>
            </section>

            {/* Motorsports Event Space Section */}
            <section className="bg-stone-100 py-12 relative">
                <div className="container mx-auto flex flex-col lg:flex-row items-center">

                    <div className="lg:w-1/2 bg-white p-8 rounded-lg shadow-lg text-center lg:text-left">
                        <h2 className="text-4xl font-bold text-gray-800 mb-8">Motorsports Event Space</h2>
                        <p className="text-lg text-gray-700 dark:text-gray-500 mb-6">
                            Beyond just selling luxury cars, we are passionate about the motorsports lifestyle. Our facility includes a dedicated motorsports event space, designed to bring together enthusiasts and professionals in a dynamic environment. Whether it's hosting exclusive car shows, motorsport events, or community gatherings, our space is the perfect venue to celebrate the thrill of high-performance automobiles.
                        </p>
                    </div>
                    <div className="lg:w-1/2 lg:pr-8 mb-8 ml-14 lg:mb-0">
                        <img src="https://lh3.googleusercontent.com/p/AF1QipN3Ba8mW9D3mXeU_PW_gN0rAjVXYA2Crk11qkzm=s1360-w1360-h1020" alt="Motorsports Event" className="w-full h-auto object-cover rounded-lg shadow-lg" />
                    </div>
                </div>
            </section>




            <section className="bg-gray-50 dark:bg-gray-800">
                <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-10 lg:pt-28">
                    <div className="hidden lg:mt-0 lg:col-span-7 lg:flex px-20">
                        <img src="https://lh3.googleusercontent.com/p/AF1QipP5TPLu7zWyJu6vgyI2O7JV9QkrOejAJcui6YGJ=s1360-w1360-h1020" alt="hero image" />
                    </div>
                    <figure className="max-w-screen-md mx-auto">
                        <h2 className="text-3xl font-bold text-gray-300 mb-8">Stay Connected</h2>

                        <p className="text-xl font-medium text-white md:text-2xl dark:text-white">

                            Check out our latest videos and updates on our YouTube channel and all our social media platform.

                        </p>

                        <figcaption className="flex items-center justify-center mt-6 space-x-3">
                            <img
                                className="w-6 h-6 rounded-full"
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxEFzVW1jtTWb286IUipQC4MuBXdcZsSGgJIrQH7nTj0mhKOyf9nlfMWOixwsR3y3Ejrc&usqp=CAU"
                                alt="profile picture"
                            />
                            <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                                <div className="pr-3 font-medium text-gray-200 dark:text-white">
                                    Our directory
                                </div>
                                <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
                                    Youtube, Instagram, Tiktok &amp; Facebook
                                </div>
                            </div>
                            {/* YouTube SVG Icon */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-red-600 dark:text-red-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 0C4.477 0 0 4.477 0 10c0 5.523 4.477 10 10 10s10-4.477 10-10c0-5.523-4.477-10-10-10zM8 14.667V5.333l6.667 4.167-6.667 4.167z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </figcaption>
                    </figure>
                </div>
            </section>




            {/* Our Commitment Section */}
            <section className="bg-white py-12">
                <div className="container mx-auto">
                    <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">Our Commitment</h2>
                    <p className="text-lg text-gray-700 dark:text-gray-500 mb-6 text-center">
                        At D.T.C Motors, our mission is to provide exceptional service and unparalleled quality. Our experienced team is dedicated to ensuring that every customer finds the perfect car that fits their lifestyle and needs. We pride ourselves on our integrity, expertise, and the lasting relationships we build with our customers.
                    </p>
                </div>
            </section>


            <section className="bg-gray-50 dark:bg-gray-800">
                <h1 className="max-w-2xl pt-20 pl-20 text-4xl font-extrabold leading-none tracking-tight text-white">
                    Visit &
                    <br />
                    Contact Us
                </h1>
                <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
                    <div className="grid gap-6 row-gap-10 lg:grid-cols-2">
                        <div className="lg:py-6 lg:pr-16">
                            <div className="flex">
                                <div className="flex flex-col items-center mr-4">
                                    <div>
                                        <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6 text-white"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="w-px h-full bg-gray-300" />
                                </div>
                                <div className="pt-1 pb-8">
                                    <p className="mb-2 text-lg font-bold text-white">
                                        Our headquarters is conveniently located at:
                                    </p>
                                    <p className="text-white">
                                        D.T.C Motors Sdn Bhd (HQ)
                                        253 Jalan Ampang Hilir, Off Jalan Ampang,
                                        Kuala Lumpur, Malaysia, 50450
                                    </p>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="flex flex-col items-center mr-4">
                                    <div>
                                        <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                                        <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
  class="w-6 h-6 text-white"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M12 6v2m0 4h.01M12 18v-6"
  />
</svg>

                                        </div>
                                    </div>

                                </div>
                                <div className="pt-1 pb-8">
                                    <p className="mb-2 text-lg text-white font-bold">
                                        For inquiries or more information
                                    </p>
                                    <p className="text-white">
                                        Please visit our website or get in touch with us directly. Your dream car awaits at D.T.C Motors Sdn Bhd.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="lg:pr-8 mb-8 ml-14 lg:mb-0">
                        <img src="https://lh3.googleusercontent.com/p/AF1QipN3Ba8mW9D3mXeU_PW_gN0rAjVXYA2Crk11qkzm=s1360-w1360-h1020" alt="Motorsports Event" className="w-full h-auto object-cover rounded-lg shadow-lg" />
                    </div> 
                    </div>
                </div>
            </section>
            {/* End block */}





            <Footer />
        </>
    );
};

export default AboutPage;
