import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const images = [
    "https://lh3.googleusercontent.com/p/AF1QipNJBs3NzJtwsODq0fleor7jlLaUw1pm1UFOIFFR=s1360-w1360-h1020",
    "https://lh3.googleusercontent.com/p/AF1QipP5JqJ1zmEBdGPti2HhXLuSg-dwMgq28vAhrBOc=s1360-w1360-h1020",
    "https://lh3.googleusercontent.com/p/AF1QipP5TPLu7zWyJu6vgyI2O7JV9QkrOejAJcui6YGJ=s1360-w1360-h1020"
];

const NextArrow = ({ onClick }) => {
    return (
        <div className="slick-arrow slick-next" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right w-8 h-8 text-white"
            >
                <line x1="5" y1="12" x2="19" y2="12" />
                <polyline points="12 5 19 12 12 19" />
            </svg>
        </div>
    );
};

const PrevArrow = ({ onClick }) => {
    return (
        <div className="slick-arrow slick-prev" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-left w-8 h-8 text-white"
            >
                <line x1="19" y1="12" x2="5" y2="12" />
                <polyline points="12 19 5 12 12 5" />
            </svg>
        </div>
    );
};

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
};

const BackgroundCarousel = () => {
    const [filters, setFilters] = useState({
        carName: '',
        model: '',
        bodyType: '',
        priceRange: '',
        yearRange: '',
        transmission: '',
        mileage: '',
        color: '',
        center: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Implement the logic to handle the search
        console.log('Filters submitted:', filters);
    };

    const handleReset = () => {
        setFilters({
            carName: '',
            model: '',
            bodyType: '',
            priceRange: '',
            yearRange: '',
            transmission: '',
            mileage: '',
            color: '',
            center: ''
        });
    };

    return (
        <section className="relative">
            <Slider {...settings} className="w-full h-screen">
            {images.map((image, index) => (
                <div key={index} className="w-full h-screen relative">
                    <div className="absolute inset-0 bg-black opacity-50"></div> {/* Semi-transparent overlay */}
                    <img src={image} alt={`Slide ${index + 1}`} className="w-full h-full object-cover" />
                </div>
            ))}
            </Slider>
  <div className="absolute inset-0 flex mt-60 flex-col items-center justify-center">
                <p className="font-bold font-sans mb-1 text-2xl text-white">
                    DTC Motors
                </p>

                <h1 class="font-bold mb-16 text-5xl text-white md:leading-tight lg:leading-tight lg:text-6xl text-center shadow-lg">
    <span class="block">
        <span class="text-red-500">D</span>istinguished, 
        <span class="text-red-500"> T</span>rusted, and
        <span class="text-red-500"> C</span>urated.
    </span>
    
    <span class="block">Just for you.</span>
</h1>




                <div className="bg-white/65 bg-opacity-75 text-gray-800 p-8 max-w-3xl mx-auto rounded-lg shadow-lg">

                    <h2 className="font-bold mb-2 text-gray-900 text-xl text-center">
                        Let's find your ideal car
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <div className="-mx-1 flex flex-wrap items-center mb-4">
                            <div className="p-1 w-full sm:flex-1">
                                <input
                                    className="shadow-md border px-5 py-2 text-gray-600 w-full"
                                    type="text"
                                    name="carName"
                                    value={filters.carName}
                                    onChange={handleChange}
                                    required
                                    placeholder="Search for car models"
                                />
                            </div>
                            <div className="p-1 text-right w-full sm:flex-initial sm:w-auto">
                                <button type="submit"
                                    className="bg-red-500 hover:bg-primary-600 inline-block px-6 py-2 text-center text-white"
                                >
                                    <span>Search</span>
                                </button>
                            </div>
                        </div>
                        <div className="w-full shadow p-4 px-12 mb-10 rounded-lg bg-white bg-opacity-90">
                            <div className="relative">
                                <div className="flex items-center justify-between mt-4">
                                    <h2 className="font-bold mb-2 text-gray-900 text-xl">
                                        Filter
                                    </h2>
                                    <button onClick={handleReset}
                                        className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 text-sm font-medium rounded-md">
                                        Reset Filter
                                    </button>
                                </div>
                                <div>
                                    <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 mt-4">
                                        <select
                                            name="model"
                                            value={filters.model}
                                            onChange={handleChange}
                                            className="px-2 py-3 w-full md:w-36 rounded-md bg-gray-200 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                                        >
                                            <option value="">Any Brand</option>
                                            <option value="Aston Martin">Aston Martin</option>
                                            <option value="Bentley">Bentley</option>
                                            <option value="BMW">BMW</option>
                                            <option value="Bugatti">Bugatti</option>
                                            <option value="Ferrari">Ferrari</option>
                                            <option value="Lamborghini">Lamborghini</option>
                                            <option value="Land Rover">Land Rover</option>
                                            <option value="Maserati">Maserati</option>
                                            <option value="Mercedes">Mercedes</option>
                                            <option value="Porsche">Porsche</option>
                                            <option value="Rolls-Royce">Rolls-Royce</option>
                                            <option value="Tesla">Tesla</option>
                                        </select>
                                        <select
                                            name="bodyType"
                                            value={filters.bodyType}
                                            onChange={handleChange}
                                            className="px-2 py-3 w-full md:w-36 ml-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                                        >
                                            <option value="">Any Body Type</option>
                                            <option value="Hatchback">Hatchback</option>
                                            <option value="Sedan">Sedan</option>
                                            <option value="SUV">SUV</option>
                                            <option value="MUV">MUV</option>
                                            <option value="Coupe">Coupe</option>
                                            <option value="Convertible">Convertible</option>
                                            <option value="Pickup Truck">Pickup Truck</option>
                                            <option value="Sports Car">Sports Car</option>
                                        </select>
                                        <select
                                            name="priceRange"
                                            value={filters.priceRange}
                                            onChange={handleChange}
                                            className="px-1 py-3 w-full md:w-36 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                                            >
                                            <option value="">Any Price</option>
                                            <option value={1}>&lt; RM 300,000</option>
                                            <option value={2}>&lt; RM 600,000</option>
                                            <option value={3}>&lt; RM 900,000</option>
                                            <option value={4}>&lt; RM 1,000,000</option>
                                        </select>
                                        <select
                                            name="yearRange"
                                            value={filters.yearRange}
                                            onChange={handleChange}
                                            className="px-2 py-3 w-full md:w-36 ml-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                                            >
                                            <option value="">Any Year</option>
                                            <option value={1}>&lt; 2015</option>
                                            <option value={2}>2016 - 2020</option>
                                            <option value={3}>2021 - {new Date().getFullYear() - 1}</option>
                                            <option value={4}>{new Date().getFullYear()}</option>
                                        </select>
                                        <select
                                            name="transmission"
                                            value={filters.transmission}
                                            onChange={handleChange}
                                            className="px-1 py-3 w-full md:w-36 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                                        >
                                            <option value="">Any Transmission</option>
                                            <option value="Manual">Manual</option>
                                            <option value="Automatic">Automatic</option>
                                        </select>
                                        <select
                                            name="mileage"
                                            value={filters.mileage}
                                            onChange={handleChange}
                                            className="px-2 py-3 w-full md:w-36 ml-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                                        >
                                            <option value="">Any Mileage</option>
                                            <option value={1}>&lt; 50,000</option>
                                            <option value={2}>&lt; 100,000</option>
                                            <option value={3}>&lt; 150,000</option>
                                            <option value={4}>&lt; 200,000</option>
                                        </select>
                                        <select
                                            name="color"
                                            value={filters.color}
                                            onChange={handleChange}
                                            className="px-1 py-3 w-full md:w-36 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                                        >
                                            <option value="">Any Color</option>
                                            <option value="Black">Black</option>
                                            <option value="White">White</option>
                                            <option value="Grey">Grey</option>
                                            <option value="Red">Red</option>
                                            <option value="Blue">Blue</option>
                                            <option value="Green">Green</option>
                                            <option value="Yellow">Yellow</option>
                                        </select>
                                        <select
                                            name="center"
                                            value={filters.center}
                                            onChange={handleChange}
                                            className="px-2 py-3 w-full md:w-36 ml-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm text-gray-600"
                                            >
                                            <option value="">Any Center</option>
                                            <option value="Kuala Lumpur">Kuala Lumpur</option>
                                            <option value="Selangor">Selangor</option>
                                            <option value="Penang">Penang</option>
                                            <option value="Johor">Johor</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>



        </section>
    );
};

export default BackgroundCarousel;
